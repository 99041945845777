import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import PoolIcon from "@material-ui/icons/Pool";
import { useSnackbar } from "notistack";
import { defineMessages, useIntl } from "react-intl";

import {
	CourseDetailsView,
	EnrolledPersonView,
	EnrollmentForm,
	findAcceptedEnrollments,
	findPendingEnrollments,
	findWaitingPersons,
} from "../components";
import {
	CourseStore,
	PersonStore,
	EnrollmentStore,
	State,
	UserStore,
	WaitingListStore,
} from "../stores";
import { useStyles } from "../assets";
import {
	Course,
	EnrollmentCreationDto,
	Role,
	WaitingListElementDto,
} from "../types";
import "./pages.css";

const messages = defineMessages({
	errorMessage: {
		id: "courseDetails.errorMessage",
		defaultMessage: "An error occured at the creation! Please try again!",
	},

	fullCapacity: {
		id: "courseDetails.fullCapacity",
		defaultMessage:
			"Sorry, you can't enroll anybody to this course! There are no more spaces!",
	},

	enrolled: {
		id: "courseDetails.enrolled",
		defaultMessage: "Enrollment was sent!",
	},

	enrolledToWaitingList: {
		id: "courseDetails.enrolledToWaitingList",
		defaultMessage:
			"The person was enrolled to the waiting list successfully!",
	},

	alreadyEnrolled: {
		id: "courseDetails.alreadyEnrolled",
		defaultMessage:
			"The person has been already enrolled to the waiting list!",
	},

	enroll: {
		id: "courseDetails.enroll",
		defaultMessage: "Enroll",
	},

	enrollToTheWaitingList: {
		id: "courseDetails.enrollToTheWaitingList",
		defaultMessage: "Enroll to the waiting list",
	},

	fullCourse: {
		id: "enrollment.fullCourse",
		defaultMessage:
			"The course is full. You can enroll persons only to the waiting list.",
	},
});

const initialValuesForEnrollment: EnrollmentCreationDto = {
	personId: -1,
	courseId: 0,
	notes: "",
};

const initialValuesForWaitingList: WaitingListElementDto = {
	personId: -1,
	courseId: 0,
	notes: "",
};

export const CourseDetails: FC = () => {
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();

	// --- CONTAINERS ---
	const { currentUser } = UserStore.useContainer();
	const { listPersons, personList } = PersonStore.useContainer();
	const { getDetails, currentCourse } = CourseStore.useContainer();
	const {
		createEnrollment,
		enrollmentCreationState,
		getEnrolledPersons,
		enrolledPersons,
		getEnrolledPersonsState,
		findEnrollmentsByCourseId,
		enrollmentsForCourse,
		findEnrollmentsByCourseIdState,
	} = EnrollmentStore.useContainer();
	const {
		addPersonToWaitingList,
		addingPersonToWaitingListState,
		waitingPersonList,
		getPersonsOnWaitingListByCourseIdState,
		getPersonsOnWaitingListByCourseId,
	} = WaitingListStore.useContainer();

	// --- EFFECTS ---

	// load the necessary information for display and interaction
	useEffect(() => {
		const courseId = Number(id);
		// details for the course
		getDetails(courseId);

		// persons to find out who is not enrolled
		getEnrolledPersons(courseId);
		listPersons();

		// enrollments to see state (accepted/pending)
		findEnrollmentsByCourseId(courseId);

		getPersonsOnWaitingListByCourseId(courseId);
	}, [
		getDetails,
		getEnrolledPersons,
		listPersons,
		findEnrollmentsByCourseId,
		getPersonsOnWaitingListByCourseId,
		id,
	]);

	// manage enroll attempts and give feedback
	useEffect(() => {
		if (enrollmentCreationState.state === State.DONE) {
			const courseId = Number(id);
			getDetails(courseId);
			getEnrolledPersons(courseId);
			findEnrollmentsByCourseId(courseId);
			enqueueSnackbar(formatMessage(messages.enrolled), {
				variant: "success",
			});
		} else if (enrollmentCreationState.state === State.ERROR) {
			if (enrollmentCreationState.error === 400) {
				enqueueSnackbar(formatMessage(messages.fullCapacity), {
					variant: "error",
				});
			} else {
				enqueueSnackbar(formatMessage(messages.errorMessage), {
					variant: "error",
				});
			}
			enrollmentCreationState.reset();
		}
	}, [
		enqueueSnackbar,
		enrollmentCreationState,
		findEnrollmentsByCourseId,
		formatMessage,
		getDetails,
		getEnrolledPersons,
		id,
	]);

	useEffect(() => {
		if (addingPersonToWaitingListState.state === State.DONE) {
			const courseId = Number(id);
			getPersonsOnWaitingListByCourseId(courseId);
			enqueueSnackbar(formatMessage(messages.enrolledToWaitingList), {
				variant: "success",
			});
		} else if (addingPersonToWaitingListState.state === State.ERROR) {
			if (addingPersonToWaitingListState.error === 405) {
				enqueueSnackbar(formatMessage(messages.alreadyEnrolled), {
					variant: "error",
				});
			} else {
				enqueueSnackbar(formatMessage(messages.errorMessage), {
					variant: "error",
				});
			}
			addingPersonToWaitingListState.reset();
		}
	}, [
		addingPersonToWaitingListState,
		getPersonsOnWaitingListByCourseId,
		enqueueSnackbar,
		id,
		formatMessage,
	]);

	useEffect(() => {
		if (findEnrollmentsByCourseIdState.state === State.DONE) {
			findEnrollmentsByCourseIdState.reset();
		}
	}, [findEnrollmentsByCourseIdState]);

	useEffect(() => {
		if (getEnrolledPersonsState.state === State.DONE) {
			getEnrolledPersonsState.reset();
		}
	}, [getEnrolledPersonsState]);

	useEffect(() => {
		if (getPersonsOnWaitingListByCourseIdState.state === State.DONE) {
			getPersonsOnWaitingListByCourseIdState.reset();
		}
	}, [getPersonsOnWaitingListByCourseIdState]);

	// --- DISPLAY ---

	const normalUserUI = (course: Course) => {
		const accepted = findAcceptedEnrollments(enrollmentsForCourse, course);
		const pending = findPendingEnrollments(enrollmentsForCourse, course);
		let waiting = findWaitingPersons(
			waitingPersonList,
			personList,
			currentUser
		)!;

		return (
			<div className="enrollDiv">
				<CourseDetailsView course={course} />
				<div className="innerEnrollDiv">
					{course.nrOfEnrolledPersons == course.participationLimit ? (
						<EnrollmentForm
							onSubmit={addPersonToWaitingList}
							requestResult={addingPersonToWaitingListState}
							persons={personList}
							enrolledPersons={enrolledPersons}
							personsOnWaitingList={waitingPersonList}
							submitButtonText={formatMessage(
								messages.enrollToTheWaitingList
							)}
							fullCourse={formatMessage(messages.fullCourse)}
							initialValues={initialValuesForWaitingList}
						/>
					) : (
						<EnrollmentForm
							onSubmit={createEnrollment}
							requestResult={enrollmentCreationState}
							persons={personList}
							enrolledPersons={enrolledPersons}
							personsOnWaitingList={waitingPersonList}
							submitButtonText={formatMessage(messages.enroll)}
							fullCourse=""
							initialValues={initialValuesForEnrollment}
						/>
					)}
					<EnrolledPersonView
						approvedEnrollments={accepted}
						pendingEnrollments={pending}
						waitingListElements={waiting}
					/>
				</div>
			</div>
		);
	};

	const adminUserUI = (course: Course) => {
		const accepted = findAcceptedEnrollments(enrollmentsForCourse, course);
		const pending = findPendingEnrollments(enrollmentsForCourse, course);
		let waiting = findWaitingPersons(
			waitingPersonList,
			personList,
			currentUser
		)!;

		return (
			<div className="enrollDiv">
				<CourseDetailsView course={course} />
				<div className="innerEnrollDiv">
					<EnrolledPersonView
						approvedEnrollments={accepted}
						pendingEnrollments={pending}
						waitingListElements={waiting}
						className="bigFlexList"
					/>
				</div>
			</div>
		);
	};

	return (
		<div className="mainDiv">
			<div className="iconDiv">
				<PoolIcon className={classes.mainLogo} />
			</div>

			{currentCourse == undefined
				? undefined
				: currentUser?.role === Role.NORMAL
				? normalUserUI(currentCourse)
				: adminUserUI(currentCourse)}
		</div>
	);
};
