import React, { FC, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	Button,
	CircularProgress,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";
import * as yup from "yup";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { shouldShowErrorMessage, withTime } from "../utils";
import { Absence, Training } from "../../types";
import { RequestResult, State } from "../../stores/types";
import "./formStyle.css";
import { AbsenceRecuperationCreationDto } from "../../types";
import { SubmitProgress } from ".";
import { frequentMessages } from "../../locales";
import { TrainingStore } from "../../stores";

const messages = defineMessages({
	cancellationReasonTitle: {
		id: "recuperationAddForm.cancellationReasonTitle",
		defaultMessage: "Reason for cancellation",
	},
	noTrainings: {
		id: "recuperationAddForm.noTrainings",
		defaultMessage: "There are no available trainings",
	},
	recuperationTrainingId: {
		id: "recuperationAddForm.recuperationTrainingId",
		defaultMessage: "This person will recuperate absence on",
	},
	requiredRecuperationTrainingId: {
		id: "recuperationAddForm.requiredRecuperationTrainingId",
		defaultMessage: "Training required!",
	},
});

type Props = {
	onSubmit: (
		absenceId: number,
		recuperation: AbsenceRecuperationCreationDto
	) => void;
	absence: Absence;
	availableTrainings: Training[];
	requestResult: RequestResult;
};

export const PersonRecuperationForm: FC<Props> = ({
	onSubmit,
	absence,
	availableTrainings,
	requestResult,
}) => {
	const { formatMessage, formatDate, formatTime } = useIntl();
	const { getCancellationReasonState, getCancellationReason } =
		TrainingStore.useContainer();
	const [cancelReason, setCancelReason] = useState<string | undefined>(
		undefined
	);

	const validationSchema = yup.object().shape({
		recuperationTrainingId: yup
			.number()
			.required(formatMessage(messages.requiredRecuperationTrainingId)),
	});

	availableTrainings = availableTrainings.sort(
		(a, b) =>
			new Date(withTime(a.date, a.course.startHour)).getTime() -
			new Date(withTime(b.date, b.course.startHour)).getTime()
	);

	const initialValues: AbsenceRecuperationCreationDto = {
		recuperationTrainingId:
			availableTrainings.length > 0 ? availableTrainings[0].id : 0,
	};

	useEffect(() => {
		const fetchReason = async () => {
			if (absence.absenceTraining.isCancelled) {
				setCancelReason(
					await getCancellationReason(absence.absenceTraining.id)
				);
			}
		};
		fetchReason();
	}, [
		absence.absenceTraining.id,
		absence.absenceTraining.isCancelled,
		getCancellationReason,
	]);

	return (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				onSubmit(absence.id, values);
			}}
		>
			{(formikProps) => {
				return (
					<Form className="">
						<div className="inputElement">
							<Field
								as={TextField}
								label={
									<FormattedMessage
										{...messages.recuperationTrainingId}
									/>
								}
								select
								id="recuperationTrainingId"
								name="recuperationTrainingId"
								color="primary"
								variant="outlined"
								onChange={formikProps.handleChange}
								error={shouldShowErrorMessage(
									formikProps,
									"recuperationTrainingId"
								)}
								className="wide"
							>
								{availableTrainings.length == 0 && (
									<MenuItem key={0} value={0} disabled>
										<FormattedMessage
											{...messages.noTrainings}
										/>
									</MenuItem>
								)}
								{availableTrainings.map((training) => {
									return (
										<MenuItem
											key={training.id}
											value={training.id}
										>
											{training.course.type}
											{" - "}
											{formatDate(training.date)}{" "}
											{formatTime(
												withTime(
													training.date,
													training.course.startHour
												)
											)}
										</MenuItem>
									);
								})}
							</Field>
							<ErrorMessage name="recuperationTrainingId">
								{(msg) => (
									<div className="errorMessage">{msg}</div>
								)}
							</ErrorMessage>
						</div>

						<Button
							type="submit"
							variant="contained"
							value="Submit"
							color="primary"
							disabled={!formikProps.isValid}
						>
							<FormattedMessage {...frequentMessages.add} />
						</Button>

						<SubmitProgress requestResult={requestResult} />
						{absence.absenceTraining.isCancelled ? (
							<div style={{ marginTop: "25px" }}>
								<Typography variant="h5" gutterBottom>
									<FormattedMessage
										{...messages.cancellationReasonTitle}
									/>
								</Typography>
								<Typography
									variant="body1"
									align="justify"
									style={{
										margin: "15px",
										wordWrap: "break-word",
									}}
								>
									{getCancellationReasonState.state ===
									State.DONE ? (
										cancelReason
									) : (
										<CircularProgress />
									)}
								</Typography>
							</div>
						) : null}
					</Form>
				);
			}}
		</Formik>
	);
};
