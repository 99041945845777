import React, { FC, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useFormikContext } from "formik";

import { RequestResult, State } from "../../stores/types";

type ProgressProps = {
	requestResult: RequestResult;
};

export const SubmitProgress: FC<ProgressProps> = ({ requestResult }) => {
	const formikContext = useFormikContext();

	useEffect(() => {
		if (requestResult.state === State.DONE) {
			formikContext.resetForm();
			requestResult.reset();
		}
	}, [formikContext, requestResult]);

	return (
		<>
			{requestResult.state === State.PENDING ? (
				<CircularProgress className="progress" />
			) : null}
		</>
	);
};
