import axios from "axios";

import { EnrollmentCreationDto, EnrollmentDto, PersonDto } from "../types";
import { API_URL, postRequestConfig } from "./config";

interface EnrollApi {
	postNewEnrollment(enrollment: EnrollmentCreationDto): Promise<void>;
	getEnrolledPersons(courseId: number): Promise<PersonDto[]>;
	findAllEnrollments(): Promise<EnrollmentDto[]>;
	findEnrollmentsByCourseId(courseId: number): Promise<EnrollmentDto[]>;
	acceptEnrollment(enrollmentId: number): Promise<void>;
	rejectEnrollment(enrollmentId: number): Promise<void>;
}

export const enrollmentApi: EnrollApi = {
	async postNewEnrollment(enrollment: EnrollmentCreationDto) {
		await axios.post(
			`${API_URL}/enrollments`,
			enrollment,
			postRequestConfig
		);
	},

	async getEnrolledPersons(courseId: number) {
		const response = await axios(`${API_URL}/persons?courseId=${courseId}`);
		return response.data;
	},

	// normal user will get only his enrollments
	// admin will get every enrolments
	async findAllEnrollments() {
		const response = await axios(`${API_URL}/enrollments`);
		return response.data;
	},

	async findEnrollmentsByCourseId(courseId: number) {
		const response = await axios(`${API_URL}/enrollments`, {
			params: { courseId: courseId },
		});
		return response.data;
	},

	async acceptEnrollment(enrollmentId: number) {
		await axios.patch(
			`${API_URL}/enrollments/${enrollmentId}/accept`,
			postRequestConfig
		);
	},

	async rejectEnrollment(enrollmentId: number) {
		await axios.delete(`${API_URL}/enrollments/${enrollmentId}`);
	},
};
