import { useMemo, useState } from "react";

import { State, RequestResult } from "./types";

export interface RequestState {
	state: State;
	errorStatus: number;
	pending(): void;
	done(): void;
	error(err: number): void;
	reset(): void;
}

export const useRequestState = (): RequestState => {
	const [state, setState] = useState(State.INITIAL);
	const [errorStatus, setErrorStatus] = useState(0);

	const stateControlObject: Partial<RequestState> = useMemo(
		() => ({
			pending: () => setState(State.PENDING),
			done: () => setState(State.DONE),
			error: (statusCode: number) => {
				setErrorStatus(statusCode);
				setState(State.ERROR);
			},
			reset: () => {
				setState(State.INITIAL);
				setErrorStatus(0);
			},
		}),
		[]
	);

	stateControlObject.state = state;
	stateControlObject.errorStatus = errorStatus;

	return stateControlObject as RequestState;
};

export const toRequestResult = (requestState: RequestState): RequestResult => ({
	state: requestState.state,
	error: requestState.errorStatus,
	reset: requestState.reset,
});
