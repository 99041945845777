export const API_URL = "/api";
export const USER_URL = "/account";

export const postRequestConfig = {
	headers: {
		"Content-Type": "application/json;charset=UTF-8",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Credentials": "true",
	},
};
