import React, { FC, useState } from "react";
import {
	RadioGroup,
	Radio,
	Typography,
	FormControlLabel,
} from "@material-ui/core";
import SettinsIcon from "@material-ui/icons/Settings";
import { defineMessages, useIntl } from "react-intl";

import { listStyles } from "../dataDisplay/listStyle";
import { EnrollmentEnum, SettingsKey } from "../../types";

const messages = defineMessages({
	enrollmentState: {
		id: "settings.enrollmentState",
		defaultMessage: "Enrollment state",
	},

	open: {
		id: "settings.open",
		defaultMessage: "Open",
	},

	closed: {
		id: "settings.closed",
		defaultMessage: "Closed",
	},

	forRecurringUsers: {
		id: "settings.forRecurringUsers",
		defaultMessage: "For recurring users",
	},
});

type Props = {
	enrollmentState: string;
	saveSettings: (id: string, value: string) => void;
};

export const OpenEnrollments: FC<Props> = ({
	enrollmentState,
	saveSettings,
}) => {
	const { formatMessage } = useIntl();
	const classes = listStyles();
	const [selected, setSelected] = useState(enrollmentState);

	const options: Record<EnrollmentEnum, string> = {
		[EnrollmentEnum.Closed]: formatMessage(messages.closed),
		[EnrollmentEnum.Open]: formatMessage(messages.open),
		[EnrollmentEnum.ForRecurringUsers]: formatMessage(
			messages.forRecurringUsers
		),
	};

	const handleChange = (event: any) => {
		setSelected(event.target.value);
		saveSettings(SettingsKey.Enrollment, event.target.value);
	};

	return (
		<div className={classes.columnStyle}>
			<div className={classes.rowStyle}>
				<SettinsIcon className={classes.icon} />
				<Typography className="primary">
					{formatMessage(messages.enrollmentState)}
				</Typography>
			</div>

			<RadioGroup
				aria-label="gender"
				value={selected}
				onChange={handleChange}
				style={{ marginLeft: "25px" }}
			>
				{Object.entries(options).map(([enumItem, label]) => (
					<FormControlLabel
						key={enumItem}
						value={enumItem}
						control={<Radio />}
						label={label}
					/>
				))}
			</RadioGroup>
		</div>
	);
};
