import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { LoadingSnackBar } from "../components";
import { AttendanceList, TrainingCard } from "../components/dataDisplay";
import { AttendanceStore, TrainingStore, State } from "../stores";
import { Attendance } from "../types";

export const AttendancePage: FC = () => {
	const { id } = useParams<{ id: string }>();

	const {
		listParticipationInfo,
		setPresence,
		participationInfoList,
		listParticipationInfosState,
	} = AttendanceStore.useContainer();

	const { oneTraining, getTraining, getTrainingState } =
		TrainingStore.useContainer();

	useEffect(() => {
		const trainingId = Number(id);
		listParticipationInfo(trainingId);
	}, [listParticipationInfo, id]);

	useEffect(() => {
		const trainingId = Number(id);
		getTraining(trainingId);
	}, [getTraining, id]);

	const saveAttendance = (presence: Attendance[]) => {
		if (presence != undefined && presence.length != 0) {
			setPresence(presence, Number(id));
		}
	};

	return listParticipationInfosState.state === State.DONE &&
		getTrainingState.state === State.DONE ? (
		<div>
			<TrainingCard training={oneTraining} />
			<AttendanceList
				participants={participationInfoList}
				saveAttendance={saveAttendance}
			/>
		</div>
	) : (
		<LoadingSnackBar />
	);
};
