import React, { ChangeEvent, FC, useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase } from "@material-ui/core";

import { listStyles } from "./listStyle";

type Props = {
	placeholder?: string;
	data: Object[];
	attribute: (key: any) => string;
	setResult: (target: any[]) => void;
};

export const SearchField: FC<Props> = ({
	placeholder,
	data,
	attribute,
	setResult,
}) => {
	const classes = listStyles();
	const [filteredData, setFilteredData] = useState<Object[]>(data);

	const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFilteredData(
			data.filter((item) =>
				attribute(item)
					.toLowerCase()
					.includes(e.target.value.toLowerCase())
			)
		);
	};

	useEffect(() => {
		if (filteredData.length > 0) {
			setResult(filteredData);
		}
	}, [filteredData, setResult]);

	return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				placeholder={placeholder}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				inputProps={{ "aria-label": "search" }}
				onChange={inputChange}
			/>
		</div>
	);
};
