import { useCallback } from "react";
import { createContainer } from "unstated-next";

import { registrationApi } from "../api";
import { RegistrationDto } from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface RegistrationStoreResult {
	registerUserState: RequestResult;
	registerUser(user: RegistrationDto): Promise<void>;
}

const useRegistration = (): RegistrationStoreResult => {
	const registerUserState = useRequestState();
	const registerUser = useCallback(
		async (user: RegistrationDto) => {
			try {
				registerUserState.pending();

				await registrationApi.createNewUser(user);

				registerUserState.done();
			} catch (error) {
				registerUserState.error(error?.response?.status ?? 0);
			}
		},
		[registerUserState]
	);
	return {
		registerUserState: toRequestResult(registerUserState),
		registerUser,
	};
};

export const RegistrationStore = createContainer(useRegistration);
