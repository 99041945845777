import axios from "axios";

import { CourseType } from "../types";
import { API_URL, postRequestConfig } from "./config";

interface CourseTypeApi {
	postNewCourseType(courseType: CourseType): Promise<void>;
	getCourseTypes(): Promise<CourseType[]>;
}

export const courseTypeApi: CourseTypeApi = {
	async postNewCourseType(courseType: CourseType) {
		await axios.post(
			`${API_URL}/courseTypes`,
			courseType,
			postRequestConfig
		);
	},

	async getCourseTypes() {
		const response = await axios.get(`${API_URL}/courseTypes`);
		return response.data;
	},
};
