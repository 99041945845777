import React, { FC, useEffect, useState } from "react";

import {
	CourseListView,
	LoadingSnackBar,
	SubscriptionList,
} from "../components";
import { ApprovedEnrollments } from "../components/dataDisplay/ApprovedEnrollments";
import { CourseStore, EnrollmentStore, State } from "../stores";
import { CourseReducedDto, EnrollmentDto } from "../types";

export const EnrollmentsHandler: FC = () => {
	// --- CONTAINERS ---
	const { courseList, listCourses, listCoursesState } =
		CourseStore.useContainer();
	const {
		rejectEnrollment,
		acceptEnrollment,
		acceptEnrollmentState,
		rejectEnrollmentState,
		findEnrollmentsByCourseId,
		findEnrollmentsByCourseIdState,
		enrollmentsForCourse,
	} = EnrollmentStore.useContainer();

	// --- STATES ----
	const [selectedCourse, setSelectedCourse] = useState<
		CourseReducedDto | undefined
	>(undefined);

	const [waitersForSelected, setWaitersForSelected] = useState<
		EnrollmentDto[]
	>([]);

	const [approvedForSelected, setApprovedForSelected] = useState<
		EnrollmentDto[]
	>([]);

	// --- FUNCTIONS ---
	const accept = (enrollment: EnrollmentDto) => {
		const newWaiters = waitersForSelected.filter(
			(waiterEnrollment) => waiterEnrollment !== enrollment
		);
		const newApproved = approvedForSelected.concat(enrollment);

		setWaitersForSelected(newWaiters);
		setApprovedForSelected(newApproved);
		acceptEnrollment(enrollment.id);
	};

	const reject = (enrollment: EnrollmentDto) => {
		const newWaiters = waitersForSelected.filter(
			(waiterEnrollment) => waiterEnrollment !== enrollment
		);

		setWaitersForSelected(newWaiters);
		rejectEnrollment(enrollment.id);
	};

	// --- EFFECTS ---
	// load courses and all enrollments when component is mounted
	useEffect(() => {
		listCourses();
	}, [listCourses]);

	// get all enrollments for the selected course
	useEffect(() => {
		if (typeof selectedCourse !== "undefined") {
			findEnrollmentsByCourseId(selectedCourse.id);
		}
	}, [findEnrollmentsByCourseId, selectedCourse]);

	// after getting the enrollments for a course filter them to
	// place in corresponding groups
	useEffect(() => {
		if (findEnrollmentsByCourseIdState.state === State.DONE) {
			const waitingEnrollments = enrollmentsForCourse.filter(
				(e) => !e.accepted
			);
			const approvedEnrollments = enrollmentsForCourse.filter(
				(e) => e.accepted
			);
			setWaitersForSelected(waitingEnrollments);
			setApprovedForSelected(approvedEnrollments);
			findEnrollmentsByCourseIdState.reset();
		}
	}, [enrollmentsForCourse, findEnrollmentsByCourseIdState, selectedCourse]);

	// --- DISPLAY ---
	return listCoursesState.state === State.DONE ? (
		<div className="container">
			<CourseListView
				courseList={courseList}
				setSelected={(course: CourseReducedDto) => {
					setSelectedCourse(course);
				}}
			/>
			<SubscriptionList
				enrollmentList={waitersForSelected}
				accept={accept}
				reject={reject}
			/>
			<ApprovedEnrollments enrollments={approvedForSelected} />

			{acceptEnrollmentState.state === State.PENDING ||
			findEnrollmentsByCourseIdState.state === State.PENDING ||
			rejectEnrollmentState.state == State.PENDING ? (
				<LoadingSnackBar />
			) : null}
		</div>
	) : (
		<LoadingSnackBar />
	);
};
