import axios from "axios";

import { Absence, Training } from "../types";
import {
	Person,
	PersonCreationDto,
	PersonDto,
	PersonEditDto,
} from "../types/person";
import { API_URL, postRequestConfig } from "./config";

interface PersonApi {
	postNewPerson(person: PersonCreationDto): Promise<void>;
	findAllPerson(): Promise<PersonDto[]>;
	findDetailedInformation(id: number): Promise<Person>;
	editPerson(id: number, person: PersonEditDto): Promise<void>;
	listAllTrainingsForPerson(personId: number): Promise<Training[]>;
	findAllAbsencesForPerson(personId: number): Promise<Absence[]>;
}

export const personApi: PersonApi = {
	async postNewPerson(person: PersonCreationDto) {
		await axios.post(`${API_URL}/persons`, person, postRequestConfig);
	},
	async findAllPerson() {
		const response = await axios(`${API_URL}/persons`);
		return response.data;
	},
	async findDetailedInformation(id: number) {
		const response = await axios(`${API_URL}/persons/${id}`);
		return response.data;
	},
	async editPerson(id: number, person: PersonEditDto) {
		await axios.patch(
			`${API_URL}/persons/${id}`,
			person,
			postRequestConfig
		);
	},
	async listAllTrainingsForPerson(personId: number) {
		const response = await axios(
			`${API_URL}/persons/${personId}/trainings`
		);
		return response.data;
	},
	async findAllAbsencesForPerson(personId: number) {
		const response = await axios.get(
			`${API_URL}/persons/${personId}/absences`
		);
		return response.data;
	},
};
