import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { hu as Hungarian } from "../locales";

export interface SelectLanguage {
	messages: any;
	locale: string;
	selectLanguage(locale: string): void;
}

const useLanguage = (): SelectLanguage => {
	const [messages, setMessages] = useState<any>(undefined);
	const [locale, setLocale] = useState("en");

	const selectLanguage = useCallback((newLocale: string) => {
		if (newLocale === "hu") {
			setMessages(Hungarian);
		} else {
			setMessages(undefined);
		}
		setLocale(newLocale);
	}, []);

	return {
		messages,
		locale,
		selectLanguage,
	};
};

export const LanguageStore = createContainer(useLanguage);
