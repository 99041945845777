import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { enrollmentApi } from "../api";
import { EnrollmentCreationDto, EnrollmentDto, PersonDto } from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface EnrollmentStoreResult {
	enrollmentCreationState: RequestResult;
	getEnrolledPersonsState: RequestResult;
	findAllEnrollmentsState: RequestResult;
	findEnrollmentsByCourseIdState: RequestResult;
	acceptEnrollmentState: RequestResult;
	rejectEnrollmentState: RequestResult;

	enrolledPersons: PersonDto[];
	allEnrollments: EnrollmentDto[];
	enrollmentsForCourse: EnrollmentDto[];

	createEnrollment(enrollment: EnrollmentCreationDto): Promise<void>;
	getEnrolledPersons(courseId: number): Promise<void>;
	findAllEnrollments(): Promise<void>;
	findEnrollmentsByCourseId(courseId: number): Promise<void>;
	acceptEnrollment(enrollmentId: number): Promise<void>;
	rejectEnrollment(enrollmentId: number): Promise<void>;
}

const useEnrollmentStore = (): EnrollmentStoreResult => {
	const enrollmentCreationState = useRequestState();
	const getEnrolledPersonsState = useRequestState();
	const findAllEnrollmentsState = useRequestState();
	const findEnrollmentsByCourseIdState = useRequestState();
	const acceptEnrollmentState = useRequestState();
	const rejectEnrollmentState = useRequestState();

	const [enrolledPersons, setEnrolledPersons] = useState<PersonDto[]>([]);
	const [allEnrollments, setAllEnrollments] = useState<EnrollmentDto[]>([]);
	const [enrollmentsForCourse, setEnrollmentsForCourse] = useState<
		EnrollmentDto[]
	>([]);

	const createEnrollment = useCallback(
		async (enrollment: EnrollmentCreationDto) => {
			try {
				enrollmentCreationState.pending();

				await enrollmentApi.postNewEnrollment(enrollment);

				enrollmentCreationState.done();
			} catch (error: any) {
				enrollmentCreationState.error(error?.response?.status ?? 0);
			}
		},
		[enrollmentCreationState]
	);

	const getEnrolledPersons = useCallback(
		async (courseId: number) => {
			try {
				getEnrolledPersonsState.pending();

				const response = await enrollmentApi.getEnrolledPersons(
					courseId
				);
				setEnrolledPersons(response);

				getEnrolledPersonsState.done();
			} catch (error: any) {
				getEnrolledPersonsState.error(error?.response?.status ?? 0);
			}
		},
		[getEnrolledPersonsState]
	);

	const findAllEnrollments = useCallback(async () => {
		try {
			findAllEnrollmentsState.pending();

			const enrollmentsList = await enrollmentApi.findAllEnrollments();
			setAllEnrollments(enrollmentsList);

			findAllEnrollmentsState.done();
		} catch (error: any) {
			findAllEnrollmentsState.error(error?.response?.status ?? 0);
		}
	}, [findAllEnrollmentsState]);

	const findEnrollmentsByCourseId = useCallback(
		async (courseId: number) => {
			try {
				findEnrollmentsByCourseIdState.pending();

				const enrollmentsForCourse =
					await enrollmentApi.findEnrollmentsByCourseId(courseId);

				setEnrollmentsForCourse(enrollmentsForCourse);
				findEnrollmentsByCourseIdState.done();
			} catch (error: any) {
				findEnrollmentsByCourseIdState.error(
					error?.response?.status ?? 0
				);
			}
		},
		[findEnrollmentsByCourseIdState]
	);

	const acceptEnrollment = useCallback(
		async (enrollmentId: number) => {
			try {
				acceptEnrollmentState.pending();

				await enrollmentApi.acceptEnrollment(enrollmentId);

				acceptEnrollmentState.done();
			} catch (error: any) {
				acceptEnrollmentState.error(error?.response?.status ?? 0);
			}
		},
		[acceptEnrollmentState]
	);

	const rejectEnrollment = useCallback(
		async (enrollmentId: number) => {
			try {
				rejectEnrollmentState.pending();

				await enrollmentApi.rejectEnrollment(enrollmentId);

				rejectEnrollmentState.done();
			} catch (error: any) {
				rejectEnrollmentState.error(error?.response?.status ?? 0);
			}
		},
		[rejectEnrollmentState]
	);

	return {
		enrollmentCreationState: toRequestResult(enrollmentCreationState),
		getEnrolledPersonsState: toRequestResult(getEnrolledPersonsState),
		findAllEnrollmentsState: toRequestResult(findAllEnrollmentsState),
		findEnrollmentsByCourseIdState: toRequestResult(
			findEnrollmentsByCourseIdState
		),
		acceptEnrollmentState: toRequestResult(acceptEnrollmentState),
		rejectEnrollmentState: toRequestResult(rejectEnrollmentState),
		enrolledPersons,
		allEnrollments,
		enrollmentsForCourse,
		createEnrollment: createEnrollment,
		getEnrolledPersons,
		findAllEnrollments,
		findEnrollmentsByCourseId,
		acceptEnrollment,
		rejectEnrollment,
	};
};

export const EnrollmentStore = createContainer(useEnrollmentStore);
