import React, { FC } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, TextField, MenuItem } from "@material-ui/core";
import * as yup from "yup";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { withTime, shouldShowErrorMessage } from "../utils";
import { Person, Training } from "../../types";
import { RequestResult } from "../../stores/types";
import { SubmitProgress } from ".";
import "./formStyle.css";
import { frequentMessages } from "../../locales";
import { AbsenceCreationDto } from "../../types";

const ALLOWED_TIMEFRAME_IN_HOURS = 720;

const messages = defineMessages({
	trainingId: {
		id: "absenceAddForm.trainingId",
		defaultMessage: "This person will be absent on",
	},
	requiredTrainingId: {
		id: "absenceAddForm.requiredTrainingId",
		defaultMessage: "Training required!",
	},
	lateForSubmission: {
		id: "absenceAddForm.lateForSubmission",
		defaultMessage: "late for submission",
	},
	noTrainings: {
		id: "absenceAddForm.noTrainings",
		defaultMessage: "There are no available trainings",
	},
	cancelledTraining: {
		id: "absenceAddForm.cancelledTraining",
		defaultMessage: "This training is cancelled",
	},
});

type Props = {
	onSubmit: (absence: AbsenceCreationDto) => void;
	person: Person;
	trainings: Training[];
	requestResult: RequestResult;
};

export const PersonAbsentForm: FC<Props> = ({
	onSubmit,
	person,
	trainings,
	requestResult,
}) => {
	const { formatMessage, formatDate, formatTime } = useIntl();

	const validationSchema = yup.object().shape({
		trainingId: yup
			.number()
			.required(formatMessage(messages.requiredTrainingId)),
	});

	trainings = trainings.sort(
		(a, b) =>
			new Date(withTime(a.date, a.course.startHour)).getTime() -
			new Date(withTime(b.date, b.course.startHour)).getTime()
	);

	const initialValues: AbsenceCreationDto = {
		personId: person.id,
		trainingId: trainings.length > 0 ? trainings[0].id : 0,
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{(formikProps) => {
				return (
					<Form className="">
						<div className="inputElement">
							<Field
								as={TextField}
								label={
									<FormattedMessage
										{...messages.trainingId}
									/>
								}
								select
								id="trainingId"
								name="trainingId"
								color="primary"
								variant="outlined"
								onChange={formikProps.handleChange}
								error={shouldShowErrorMessage(
									formikProps,
									"trainingId"
								)}
								className="wide"
							>
								{trainings.length == 0 && (
									<MenuItem key={0} value={0} disabled>
										<FormattedMessage
											{...messages.noTrainings}
										/>
									</MenuItem>
								)}
								{trainings.map((training) => {
									const isLate =
										(new Date(training.date).getTime() -
											Date.now()) /
											3600000 <
										ALLOWED_TIMEFRAME_IN_HOURS;
									return (
										<MenuItem
											key={training.id}
											value={training.id}
											disabled={
												isLate || training.isCancelled
											}
										>
											{training.course.type}
											{" - "}
											{formatDate(training.date)}{" "}
											{formatTime(
												withTime(
													training.date,
													training.course.startHour
												)
											)}
											{isLate &&
												` (${formatMessage(
													messages.lateForSubmission
												)})`}
											{training.isCancelled &&
												` (${formatMessage(
													messages.cancelledTraining
												)})`}
										</MenuItem>
									);
								})}
							</Field>
							<ErrorMessage name="trainingId">
								{(msg) => (
									<div className="errorMessage">{msg}</div>
								)}
							</ErrorMessage>
						</div>

						<Button
							type="submit"
							variant="contained"
							value="Submit"
							color="primary"
							disabled={!formikProps.isValid}
						>
							<FormattedMessage {...frequentMessages.add} />
						</Button>

						<SubmitProgress requestResult={requestResult} />
					</Form>
				);
			}}
		</Formik>
	);
};
