import React, { FC } from "react";
import { Snackbar } from "@material-ui/core";
import { useIntl } from "react-intl";

import { frequentMessages } from "../locales";

export const LoadingSnackBar: FC = () => {
	const { formatMessage } = useIntl();
	return (
		<Snackbar
			open
			anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
			message={formatMessage(frequentMessages.loading)}
		/>
	);
};
