import React, { FC } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import * as yup from "yup";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { shouldShowErrorMessage, withoutTime } from "../utils";
import { DateRange, PersonCreationDto } from "../../types";
import { RequestResult } from "../../stores/types";
import { DatePicker } from "./DatePicker";
import { SubmitProgress } from ".";
import { frequentMessages } from "../../locales";
import "./formStyle.css";
import { useStyles } from "../../assets";

const messages = defineMessages({
	nameInput: {
		id: "personForm.name",
		defaultMessage: "Name",
	},

	submit: {
		id: "personForm.submit",
		defaultMessage: "Submit",
	},

	requiredName: {
		id: "personForm.requiredName",
		defaultMessage: "Name required!",
	},

	requiredDate: {
		id: "personForm.requireDate",
		defaultMessage: "Date required!",
	},

	birthDate: {
		id: "personForm.birthDate",
		defaultMessage: "Birth Date",
	},

	medicalNotes: {
		id: "personForm.medicalNotes",
		defaultMessage: "Medical Notes",
	},
});

const initialValues: PersonCreationDto = {
	name: "",
	birthDate: "",
	medicalNotes: undefined,
};

type Props = {
	onSubmit: (personCreationDto: PersonCreationDto) => void;
	requestResult: RequestResult;
};

export const PersonForm: FC<Props> = ({ onSubmit, requestResult }) => {
	const { formatMessage, formatDate } = useIntl();
	const classes = useStyles();

	const validationSchema = yup.object().shape({
		name: yup.string().required(formatMessage(messages.requiredName)),
		birthDate: yup.date().required(formatMessage(messages.requiredDate)),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				values.birthDate = withoutTime(values.birthDate);
				values.birthDate = formatDate(values.birthDate);
				if (
					values.medicalNotes != undefined &&
					values.medicalNotes.length == 0
				) {
					values.medicalNotes = undefined;
				}
				onSubmit(values);
			}}
		>
			{(formikProps) => {
				return (
					<div className="formDiv">
						<Paper variant="elevation" className="paper">
							<Form className="inputContainer">
								<PersonAddIcon className={classes.mainLogo} />
								<Typography variant="h4">
									<FormattedMessage
										{...frequentMessages.newPerson}
									/>
								</Typography>

								<div className="inputElement">
									<Field
										as={TextField}
										label={formatMessage(
											messages.nameInput
										)}
										id="name"
										name="name"
										type="text"
										color="primary"
										variant="outlined"
										onChange={formikProps.handleChange}
										error={shouldShowErrorMessage(
											formikProps,
											"name"
										)}
										className="wide"
									/>
									<ErrorMessage name="name">
										{(msg) => (
											<div className="errorMessage">
												{msg}
											</div>
										)}
									</ErrorMessage>
								</div>
								<div className="inputElement">
									<DatePicker
										label={formatMessage(
											frequentMessages.birthDate
										)}
										value={formikProps.values.birthDate}
										name="birthDate"
										format={formatDate(
											formikProps.values.birthDate
										)}
										onChange={(value) =>
											formikProps.setFieldValue(
												"birthDate",
												value ? value : ""
											)
										}
										onBlur={formikProps.handleBlur}
										errorCheck={shouldShowErrorMessage(
											formikProps,
											"birthDate"
										)}
										disableRange={DateRange.Future}
									/>
									<ErrorMessage name="birthDate">
										{(msg) => (
											<div className="errorMessage">
												{msg}
											</div>
										)}
									</ErrorMessage>
								</div>

								<div className="inputElement">
									<Field
										as={TextField}
										label={formatMessage(
											messages.medicalNotes
										)}
										value={
											formikProps.values.medicalNotes ===
											undefined
												? ""
												: formikProps.values
														.medicalNotes
										}
										multiline
										rows={4}
										variant="outlined"
										className="wide"
										onChange={formikProps.handleChange}
										name="medicalNotes"
									/>
								</div>

								<Button
									type="submit"
									variant="contained"
									value="Submit"
									color="primary"
									disabled={!formikProps.isValid}
								>
									<FormattedMessage {...messages.submit} />
								</Button>

								<SubmitProgress requestResult={requestResult} />
							</Form>
						</Paper>
					</div>
				);
			}}
		</Formik>
	);
};
