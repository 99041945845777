import React, {
	FC,
	ReactInstance,
	useCallback,
	useEffect,
	useState,
} from "react";
import Paper from "@material-ui/core/Paper";
import {
	AppointmentMeta,
	AppointmentModel,
	ViewState,
} from "@devexpress/dx-react-scheduler";
import {
	Scheduler,
	WeekView,
	Appointments,
	AppointmentTooltip,
	Toolbar,
	DateNavigator,
	TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";
import {
	Button,
	CircularProgress,
	IconButton,
	styled,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";

import { CancelDialog } from "../CancelDialog";
import { State, TrainingStore } from "../../stores";
import { frequentMessages } from "../../locales";
import "./dataDisplay.css";

const classes = {
	button: "info-button",
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	[`&.${classes.button}`]: {
		color: theme.palette.background.default,
		padding: 0,
	},
}));

type Props = {
	appointmentModel: AppointmentModel[]; // refers to the course or training
	showDays: Boolean; // show only the name of the day or day with number
	locale: string; // scheduler will use this lang.
	urlPrefix: string; // where to navigate when appointment is clicked
};

const messages = defineMessages({
	attendanceTip: {
		id: "calendarView.attendanceTip",
		defaultMessage: "View attendance list",
	},
	cancel: {
		id: "calendarView.cancel",
		defaultMessage: "Cancel",
	},
	cancelReason: {
		id: "calendarView.cancelReason",
		defaultMessage: "Cancelled because: ",
	},
	courseDetailsTip: {
		id: "calendatView.courseDetailsTip",
		defaultMessage: "Details",
	},
	today: {
		id: "calendarView.today",
		defaultMessage: "Today",
	},
});

// this is a month which starts with Monday
const defaultDate = "2021-03-01";

export const CalendarView: FC<Props> = ({
	appointmentModel,
	showDays,
	locale,
	urlPrefix,
}) => {
	const date: Date = new Date();
	const month: number = date.getMonth() + 1,
		day: number = date.getDate();
	let monthString: string, dayString: String;
	if (month < 10) {
		monthString = `0${month}`;
	} else {
		monthString = `${month}`;
	}
	if (day < 10) {
		dayString = `0${day}`;
	} else {
		dayString = `${day}`;
	}
	const [currentDate, setCurrentDate] = useState(
		showDays
			? `${date.getFullYear()}-${monthString}-${dayString}`
			: defaultDate
	);
	const [tooltipVisible, setTooltipVisibility] = useState(false);
	const history = useHistory();
	const [appointmentMeta, setAppointmentMeta] = useState<
		AppointmentMeta | undefined
	>(undefined);
	const [cancelDialogVisibility, setCancelDialogVisibility] = useState(false);
	const [selectedDataId, setSelectedDataId] = useState(0);
	const { formatTime, formatMessage } = useIntl();
	const [cancelReason, setCancelReason] = useState<string | undefined>(
		undefined
	);
	const { getCancellationReasonState, getCancellationReason } =
		TrainingStore.useContainer();

	const changeDateFormat = useCallback(
		(date: any) => {
			const newDate = new Date(date);
			return formatTime(newDate);
		},
		[formatTime]
	);

	const dateChange = (newDate: any) => {
		setCurrentDate(newDate);
	};

	useEffect(() => {
		setTooltipVisibility(false);
	}, [cancelDialogVisibility]);

	const Appointment = useCallback(
		({ children, data, draggable, resources, style }) => {
			return (
				<Appointments.Appointment
					data={data}
					style={{
						...style,
						backgroundColor: data.isCancelled
							? "#8f8d86"
							: data.startDate <= new Date()
							? new Date() <= data.endDate
								? "#320b86"
								: ""
							: "",
					}}
					onClick={async ({ target }: { target: ReactInstance }) => {
						if (urlPrefix !== "/courses") {
							setTooltipVisibility(true);
							setAppointmentMeta({ target, data });
							setSelectedDataId(data.id);

							// get cancellation reason
							if (data.isCancelled) {
								setCancelReason(
									await getCancellationReason(data.id)
								);
							}
						}
					}}
					draggable={draggable}
					resources={resources}
				>
					<StyledIconButton
						className={classes.button}
						onClick={() => {
							if (urlPrefix === "/courses") {
								history.push(`/courses/${data.id}`);
							} else if (urlPrefix === "/trainings") {
								history.push(
									`/trainings/${data.id}/attendance`
								);
							}
						}}
					>
						<Tooltip
							title={
								urlPrefix === "/courses"
									? formatMessage(messages.courseDetailsTip)
									: formatMessage(messages.attendanceTip)
							}
							aria-label={formatMessage(
								messages.attendanceTip
							).toLocaleLowerCase()}
							placement="left-start"
						>
							<InfoIcon fontSize="medium" />
						</Tooltip>
					</StyledIconButton>
					{children}
				</Appointments.Appointment>
			);
		},
		[formatMessage, getCancellationReason, history, urlPrefix]
	);

	const AppointmentContent = useCallback(
		({
			children,
			data,
			durationType,
			recurringIconComponent,
			type,
			resources,
		}) => {
			return (
				<Appointments.AppointmentContent
					data={data}
					durationType={durationType}
					recurringIconComponent={recurringIconComponent}
					type={type}
					resources={resources}
					formatDate={changeDateFormat}
				>
					{data.isCancelled ? (
						<div
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							<Typography
								variant="subtitle2"
								display="block"
								style={{
									fontWeight: "bold",
									whiteSpace: "normal",
								}}
							>
								{data.title}
							</Typography>

							<Typography variant="caption">
								{formatMessage(frequentMessages.cancelled)}
							</Typography>
						</div>
					) : (
						children
					)}
				</Appointments.AppointmentContent>
			);
		},
		[changeDateFormat, formatMessage]
	);

	const Header = useCallback(
		({
			children,
			appointmentData,
			showOpenButton,
			showCloseButton,
			showDeleteButton,
			commandButtonIds,
			commandButtonComponent,
		}) => (
			<AppointmentTooltip.Header
				appointmentData={appointmentData}
				showOpenButton={showOpenButton}
				showCloseButton={showCloseButton}
				showDeleteButton={showDeleteButton}
				commandButtonIds={commandButtonIds}
				commandButtonComponent={commandButtonComponent}
			>
				{appointmentData.isCancelled ? null : (
					<div style={{ margin: "4px" }}>
						<Button
							disableElevation
							size="medium"
							variant="text"
							endIcon={<CancelIcon />}
							onClick={() => {
								setCancelDialogVisibility(true);
							}}
						>
							{formatMessage(messages.cancel)}
						</Button>
					</div>
				)}
				{children}
			</AppointmentTooltip.Header>
		),
		[formatMessage]
	);

	const Content = ({
		appointmentData,
		recurringIconComponent,
		formatDate,
		children,
	}: any) => (
		<AppointmentTooltip.Content
			appointmentData={appointmentData}
			recurringIconComponent={recurringIconComponent}
			formatDate={formatDate}
		>
			{appointmentData.isCancelled ? (
				getCancellationReasonState.state === State.PENDING ? (
					<CircularProgress />
				) : (
					<Typography
						style={{ marginLeft: "65px" }}
						align="left"
						gutterBottom
						noWrap
					>
						{formatMessage(messages.cancelReason)}
						{cancelReason}
					</Typography>
				)
			) : (
				children
			)}
		</AppointmentTooltip.Content>
	);

	const CloseButton = () => (
		<AppointmentTooltip.CommandButton
			id="close"
			onExecute={() => {
				setTooltipVisibility(false);
			}}
		></AppointmentTooltip.CommandButton>
	);

	return (
		<Paper className={showDays ? "" : "hideDays"}>
			<Scheduler
				data={appointmentModel}
				firstDayOfWeek={1}
				locale={locale}
			>
				<ViewState
					currentDate={currentDate}
					onCurrentDateChange={dateChange}
				/>
				<WeekView startDayHour={8} endDayHour={24} />
				{showDays
					? [
							<Toolbar key="toolbar" />,
							<DateNavigator key="dateNavigator" />,
							<TodayButton
								key="todayButton"
								messages={{
									today: formatMessage(messages.today),
								}}
							/>,
					  ]
					: null}

				<Appointments
					appointmentComponent={Appointment}
					appointmentContentComponent={AppointmentContent}
				/>
				<AppointmentTooltip
					visible={tooltipVisible}
					onVisibilityChange={setTooltipVisibility}
					appointmentMeta={appointmentMeta}
					onAppointmentMetaChange={setAppointmentMeta}
					headerComponent={Header}
					showCloseButton
					commandButtonComponent={CloseButton}
					contentComponent={Content}
				/>
			</Scheduler>
			<CancelDialog
				open={cancelDialogVisibility}
				trainingId={selectedDataId}
				handleClose={() => {
					setCancelDialogVisibility(false);
				}}
			/>
		</Paper>
	);
};
