import React, { FC, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { History } from "history";

import { PersonEditForm } from "../components";
import { PersonStore, State } from "../stores";
import { PersonEditDto } from "../types";

const messages = defineMessages({
	personEdited: {
		id: "personEditFrom.personEdited",
		defaultMessage: "Person succesfully edited",
	},
	personEditError: {
		id: "personEditFrom.personEditedError",
		defaultMessage: "Failed to edit person",
	},
});

type Props = {
	history: History;
};

export const PersonEdit: FC<Props> = ({ history }) => {
	const {
		editPerson,
		editPersonState,
		listPersons,
		getPersonDetails,
		currentPerson,
	} = PersonStore.useContainer();
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();
	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		const personId = Number(id);
		getPersonDetails(personId);
	}, [getPersonDetails, id]);

	useEffect(() => {
		if (editPersonState.state === State.DONE) {
			history.goBack();
		} else if (editPersonState.state === State.ERROR) {
			enqueueSnackbar(formatMessage(messages.personEditError), {
				variant: "error",
			});
		}
	}, [
		history,
		editPersonState,
		editPersonState.state,
		enqueueSnackbar,
		formatMessage,
		listPersons,
		editPerson,
	]);

	if (!currentPerson || currentPerson.id !== Number(id)) {
		return <></>;
	}

	let onSubmit = (person: PersonEditDto) => {
		editPerson(Number(id), person);
		history.length;
	};

	return (
		<PersonEditForm
			onSubmit={onSubmit}
			person={currentPerson}
			requestResult={editPersonState}
		/>
	);
};
