import React, { FC } from "react";

import { RegistrationForm } from "../components";
import { RegistrationStore } from "../stores";

export const Registration: FC = () => {
	const registrationStore = RegistrationStore.useContainer();
	return (
		<RegistrationForm
			onSubmit={registrationStore.registerUser}
			requestResult={registrationStore.registerUserState}
		/>
	);
};
