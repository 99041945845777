import { alpha, createStyles, makeStyles, Theme } from "@material-ui/core";

import { theme as defaultTeheme } from "../../assets";

export const listStyles = makeStyles((theme: Theme) =>
	createStyles({
		light: {
			backgroundColor: theme.palette.primary.light,
			width: "30%",
			"@media (max-width: 800px)": {
				width: "100%",
				height: "20%",
				margin: 2,
			},
		},
		lightPersonListToolbar: {
			backgroundColor: theme.palette.primary.light,
		},
		fitToolbar: {
			width: 700,
			"@media (max-width: 800px)": {
				marginLeft: "10px",
				marginRight: "10px",
			},
		},
		root: {
			marginTop: 100,
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			flexWrap: "wrap",
			"@media (max-width: 800px)": {
				flexDirection: "row",
			},
		},
		button: {
			margin: "20px",
			height: "5vh",
			backgroundColor: defaultTeheme.palette.primary.dark,
			"&:hover": {
				backgroundColor: defaultTeheme.palette.secondary.dark,
			},
		},
		alignedMiddle: {
			position: "relative",
			left: "50%",
			transform: "translateX(-50%)",
			margin: "0",
			marginTop: "20px",
		},
		list: {
			width: 700,
			height: "65vh",
			overflowY: "auto",
			backgroundColor: theme.palette.background.paper,
			"&::-webkit-scrollbar": {
				width: "0.4em",
			},
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
				webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			},
			"&::-webkit-scrollbar-thumb": {
				backgroundColor: "rgba(0, 0, 0,.2)",
				outline: "1px solid slategrey",
			},
			"@media (max-width: 800px)": {
				marginLeft: "10px",
				marginRight: "10px",
			},
		},
		smallList: {
			height: "70vh",
			overflowY: "auto",
			overflowX: "hidden",
			backgroundColor: theme.palette.background.paper,
			"&::-webkit-scrollbar": {
				width: "0.4em",
			},
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
				webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			},
			"&::-webkit-scrollbar-thumb": {
				backgroundColor: "rgba(0, 0, 0,.2)",
				outline: "1px solid slategrey",
			},
			"@media (max-width: 800px)": {
				width: "100%",
				height: "20vh",
				marginLeft: 2,
			},
		},
		fitList: {
			height: 80,
			overflowY: "auto",
			width: "80%",
			backgroundColor: theme.palette.background.paper,
			"&::-webkit-scrollbar": {
				width: "0.4em",
			},
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
				webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			},
			"&::-webkit-scrollbar-thumb": {
				backgroundColor: "rgba(0, 0, 0,.2)",
				outline: "1px solid slategrey",
			},
			"@media (max-width: 900px)": {
				height: "20vh",
				width: "99%",
				textOverflow: "ellipsis",
				margin: 2,
			},
		},
		search: {
			position: "relative",
			borderRadius: theme.shape.borderRadius,
			backgroundColor: alpha(theme.palette.common.white, 0.15),
			"&:hover": {
				backgroundColor: alpha(theme.palette.common.white, 0.25),
			},
			marginRight: theme.spacing(2),
			marginLeft: 10,
			width: "80%",
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.spacing(3),
				width: "auto",
				"@media (max-width: 600px)": {
					maxWidth: "95%",
				},
			},
			display: "flex",
			alignItems: "center",
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: "100%",
			position: "relative",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		inputRoot: {
			color: "inherit",
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			transition: theme.transitions.create("width"),
			width: "100%",
		},
		paymentList: {
			overflowY: "auto",
			height: "45vh",
			backgroundColor: theme.palette.background.paper,
			"&::-webkit-scrollbar": {
				width: "0.4em",
			},
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
				webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
			},
			"&::-webkit-scrollbar-thumb": {
				backgroundColor: "rgba(0, 0, 0,.2)",
				outline: "1px solid slategrey",
			},
		},
		card: {
			width: 900,
			minHeight: 80,
			borderRadius: "0px",
			background: theme.palette.primary.light,
			display: "flex",
			justifyContent: "space-around",
			alignItems: "center",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		cardsInfo: {
			opacity: "80%",
			color: "white",
			fontSize: "18px",
			width: "auto",
		},
		cardsIcon: {
			display: "flex",
			transform: "scale(1.6)",
		},
		table: {
			width: "900px !important",
			height: "65vh",
			backgroundColor: theme.palette.background.paper,
		},
		headerCell: {
			minWidth: 30,
			fontWeight: "bold",
			textAlign: "center",
		},
		paper: {
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			flexWrap: "wrap",
			"@media (max-width: 800px)": {
				flexDirection: "row",
			},
		},
		saveAttendance: {
			width: 900,
			height: "auto",
			paddingTop: "10px",
			paddingBottom: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: theme.palette.background.paper,
		},
		columnStyle: {
			margin: "10px",
			display: "flex",
			flexDirection: "column",
			flexWrap: "wrap",
		},
		rowStyle: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		icon: {
			display: "flex",
			marginLeft: "5px",
			marginRight: "5px",
			width: "auto",
			transform: "scale(1.4)",
		},
	})
);
