import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { absenceApi } from "../api";
import {
	AbsenceCreationDto,
	Absence,
	AbsenceRecuperationCreationDto,
	Training,
} from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface AbsenceStoreResult {
	addAbsenceState: RequestResult;
	addAbsence(absence: AbsenceCreationDto): Promise<void>;
	selectedAbsenceForRecuperation?: Absence;
	selectAbsenceForRecuperation(absence: Absence): Promise<void>;
	getRecuperationTrainingsForAbsenceState: RequestResult;
	recuperationTrainingsForAbsence: Training[];
	getRecuperationTrainingsForAbsence(absenceId: number): Promise<void>;
	setAbsenceRecuperationState: RequestResult;
	setAbsenceRecuperation(
		id: number,
		recuperation: AbsenceRecuperationCreationDto
	): Promise<void>;
}

const useAbsenceStore = (): AbsenceStoreResult => {
	const addAbsenceState = useRequestState();
	const setAbsenceRecuperationState = useRequestState();
	const getRecuperationTrainingsForAbsenceState = useRequestState();

	const [selectedAbsenceForRecuperation, setSelectedAbsenceForRecuperation] =
		useState<Absence>();

	const [
		recuperationTrainingsForAbsence,
		setRecuperationTrainingsForAbsence,
	] = useState<Training[]>([]);

	const addAbsence = useCallback(
		async (absence: AbsenceCreationDto) => {
			try {
				addAbsenceState.pending();

				await absenceApi.postNewAbsence(absence);

				addAbsenceState.done();
			} catch (error: any) {
				addAbsenceState.error(error?.response?.status ?? 0);
			}
		},
		[addAbsenceState]
	);

	const selectAbsenceForRecuperation = useCallback(
		async (absence: Absence) => {
			setSelectedAbsenceForRecuperation(absence);
		},
		[]
	);

	const getRecuperationTrainingsForAbsence = useCallback(
		async (absenceId: number) => {
			try {
				getRecuperationTrainingsForAbsenceState.pending();

				const trainings =
					await absenceApi.getRecuperationTrainingsForAbsence(
						absenceId
					);
				setRecuperationTrainingsForAbsence(trainings);

				getRecuperationTrainingsForAbsenceState.done();
			} catch (error: any) {
				getRecuperationTrainingsForAbsenceState.error(
					error?.response?.status ?? 0
				);
			}
		},
		[getRecuperationTrainingsForAbsenceState]
	);

	const setAbsenceRecuperation = useCallback(
		async (id: number, recuperation: AbsenceRecuperationCreationDto) => {
			try {
				setAbsenceRecuperationState.pending();

				await absenceApi.setAbsenceRecuperation(id, recuperation);

				setAbsenceRecuperationState.done();
			} catch (error: any) {
				setAbsenceRecuperationState.error(error?.response?.status ?? 0);
			}
		},
		[setAbsenceRecuperationState]
	);

	return {
		addAbsenceState: toRequestResult(addAbsenceState),
		addAbsence,
		selectedAbsenceForRecuperation,
		selectAbsenceForRecuperation,
		getRecuperationTrainingsForAbsenceState: toRequestResult(
			getRecuperationTrainingsForAbsenceState
		),
		recuperationTrainingsForAbsence,
		getRecuperationTrainingsForAbsence,
		setAbsenceRecuperationState: toRequestResult(
			setAbsenceRecuperationState
		),
		setAbsenceRecuperation,
	};
};

export const AbsenceStore = createContainer(useAbsenceStore);
