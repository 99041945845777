import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { waitingListApi } from "../api";
import { WaitingListElementDto } from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface WaitingListStoreResult {
	addingPersonToWaitingListState: RequestResult;
	addPersonToWaitingList(
		waitingListElement: WaitingListElementDto
	): Promise<void>;
	getPersonsOnWaitingListByCourseIdState: RequestResult;
	getPersonsOnWaitingListByCourseId(courseId: number): Promise<void>;
	waitingPersonList: WaitingListElementDto[];
}

const useWaitingListStore = (): WaitingListStoreResult => {
	const addingPersonToWaitingListState = useRequestState();
	const getPersonsOnWaitingListByCourseIdState = useRequestState();
	const [waitingList, setWaitingList] = useState<WaitingListElementDto[]>([]);
	const addPersonToWaitingList = useCallback(
		async (waitingListElement: WaitingListElementDto) => {
			try {
				addingPersonToWaitingListState.pending();

				await waitingListApi.addPersonToTheWaitingList(
					waitingListElement
				);

				addingPersonToWaitingListState.done();
			} catch (error: any) {
				addingPersonToWaitingListState.error(
					error?.response?.status ?? 0
				);
			}
		},
		[addingPersonToWaitingListState]
	);

	const getPersonsOnWaitingListByCourseId = useCallback(
		async (courseId: number) => {
			try {
				getPersonsOnWaitingListByCourseIdState.pending();

				let waitingList =
					await waitingListApi.getPersonsOnWaitingListByCourseId(
						courseId
					);
				setWaitingList(waitingList);

				getPersonsOnWaitingListByCourseIdState.done();
			} catch (error: any) {
				getPersonsOnWaitingListByCourseIdState.error(
					error?.response?.status ?? 0
				);
			}
		},
		[getPersonsOnWaitingListByCourseIdState]
	);

	return {
		addingPersonToWaitingListState: toRequestResult(
			addingPersonToWaitingListState
		),
		addPersonToWaitingList: addPersonToWaitingList,
		getPersonsOnWaitingListByCourseIdState: toRequestResult(
			getPersonsOnWaitingListByCourseIdState
		),
		getPersonsOnWaitingListByCourseId: getPersonsOnWaitingListByCourseId,
		waitingPersonList: waitingList,
	};
};

export const WaitingListStore = createContainer(useWaitingListStore);
