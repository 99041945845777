import axios from "axios";

import { RegistrationDto } from "../types";
import { USER_URL, postRequestConfig } from "./config";

interface RegistrationApi {
	createNewUser(user: RegistrationDto): Promise<void>;
}

export const registrationApi: RegistrationApi = {
	async createNewUser(user: RegistrationDto) {
		await axios.post(`${USER_URL}/register`, user, postRequestConfig);
	},
};
