import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Badge,
	Button,
	List,
	ListItem,
	ListItemText,
	Toolbar,
	Typography,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import SpeakerNotesRounded from "@material-ui/icons/SpeakerNotesRounded";

import { SearchField } from ".";
import { frequentMessages } from "../../locales";
import { PersonDto, EnrollmentDto } from "../../types";
import { listStyles } from "./listStyle";
import { useStyles } from "../../assets";

const messsages = defineMessages({
	accept: {
		id: "subscriptionList.accept",
		defaultMessage: "Accept",
	},
	reject: {
		id: "subscriptionList.reject",
		defaultMessage: "Reject",
	},
	subscribers: {
		id: "subscriptionList.subscribers",
		defaultMessage: "Subscribers",
	},
	moreInfo: {
		id: "subscriptionList.moreInfo",
		defaultMessage: "More info",
	},
	medicalNotes: {
		id: "subscriptionList.medicalNotes",
		defaultMessage: "Medical Notes",
	},
});

type Props = {
	enrollmentList: EnrollmentDto[];
	accept: (enrollment: EnrollmentDto) => void;
	reject: (enrollment: EnrollmentDto) => void;
};

export const SubscriptionList: FC<Props> = ({
	enrollmentList,
	accept,
	reject,
}) => {
	const { formatMessage, formatDate } = useIntl();
	const classes = listStyles();
	const mainClasses = useStyles();

	const [enrollments, setEnrollments] = useState(enrollmentList);
	const updateEnrollments = (newEnrollments: EnrollmentDto[]) =>
		setEnrollments(newEnrollments);

	const isThereMedicalNote = (enrollment: EnrollmentDto) =>
		enrollment.person.medicalNotes != undefined &&
		enrollment.person.medicalNotes.length > 0;

	const isThereNote = (enrollment: EnrollmentDto) =>
		enrollment.notes.length > 0;

	const getNote = (enrollment: EnrollmentDto) =>
		isThereNote(enrollment) ? enrollment.notes : null;

	const primaryHead = (person: PersonDto) => (
		<Typography className="primary">
			<PersonPinIcon fontSize="medium" className="icon" />
			{person.name}
		</Typography>
	);

	const notePredictor = (enrollment: EnrollmentDto) =>
		isThereNote(enrollment) || isThereMedicalNote(enrollment) ? (
			<Badge color="secondary" variant="dot" invisible={false}>
				<SpeakerNotesRounded className="notesIcon" />
			</Badge>
		) : (
			<Badge color="secondary" variant="dot" invisible={true}>
				<SpeakerNotesRounded
					className="notesIcon"
					style={{ opacity: "40%" }}
				/>
			</Badge>
		);

	const personInfo = (enrollment: EnrollmentDto) => (
		<>
			<Accordion key="moreInfo">
				<AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
					<FormattedMessage {...messsages.moreInfo} />
					{notePredictor(enrollment)}
				</AccordionSummary>
				<AccordionDetails>
					<Typography className="additionalInfo" variant="caption">
						{`${formatMessage(
							frequentMessages.birthDate
						)}: ${formatDate(enrollment.person.birthDate)}`}
						<br />
						{`${formatMessage(frequentMessages.addedBy)}: ${
							enrollment.person.creatorName
						}`}
						<br />
						{`${formatMessage(frequentMessages.email)}: ${
							enrollment.person.creatorEmail
						}`}
						<br />
						{`${formatMessage(frequentMessages.tel)}: ${
							enrollment.person.creatorTel
						}`}
						<br />
						{enrollment.person.medicalNotes != undefined
							? `${formatMessage(messsages.medicalNotes)}: ${
									enrollment.person.medicalNotes
							  }`
							: `${formatMessage(
									messsages.medicalNotes
							  )}: ${"-"}`}
					</Typography>
					{isThereNote(enrollment) ? (
						<Typography
							variant="caption"
							className={mainClasses.accordionNote}
						>
							{`${formatMessage(
								frequentMessages.notes
							)}: ${getNote(enrollment)}`}
						</Typography>
					) : null}
				</AccordionDetails>
			</Accordion>
			<Button
				style={{ margin: 5 }}
				variant="contained"
				color="primary"
				onClick={() => accept(enrollment)}
			>
				{formatMessage(messsages.accept)}
			</Button>
			<Button
				style={{ margin: 5 }}
				variant="contained"
				color="secondary"
				onClick={() => reject(enrollment)}
			>
				{formatMessage(messsages.reject)}
			</Button>
		</>
	);

	useEffect(() => {
		setEnrollments(enrollmentList);
	}, [enrollmentList]);

	return (
		<div className={classes.light}>
			<Toolbar variant="dense">
				{formatMessage(messsages.subscribers)}
				<SearchField
					placeholder={formatMessage(frequentMessages.searchByName)}
					data={enrollments}
					attribute={(enrollment: EnrollmentDto) =>
						enrollment.person.name
					}
					setResult={updateEnrollments}
				/>
			</Toolbar>

			<List className={classes.smallList}>
				{enrollments.map((enrollment) => (
					<div key={enrollment.id}>
						<ListItem dense>
							<ListItemText
								disableTypography
								primary={primaryHead(enrollment.person)}
								secondary={personInfo(enrollment)}
							/>
						</ListItem>
						<hr />
					</div>
				))}
			</List>
		</div>
	);
};
