import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";
import { settingApi } from "../api";

export interface SettinsStoreResult {
	getSettingState: RequestResult;
	setSettingsState: RequestResult;

	settings: { [key: string]: string } | undefined;

	getSetting(): Promise<void>;
	setSettings(settings: { [key: string]: string }): Promise<void>;

	courseListDisplayMode: "list" | "calendar";
	setCourseListDisplayMode(displayMode: "list" | "calendar"): void;
}

const useSettingStore = (): SettinsStoreResult => {
	const getSettingState = useRequestState();
	const setSettingsState = useRequestState();
	const [courseListDisplayModeState, setCourseListDisplayModeState] =
		useState<"list" | "calendar">("list");

	const [settings, setSetting] = useState<
		{ [key: string]: string } | undefined
	>();

	const getSetting = useCallback(async () => {
		try {
			getSettingState.pending();

			const response = await settingApi.getSettings();
			setSetting(response);

			getSettingState.done();
		} catch (error: any) {
			getSettingState.error(error?.response?.status ?? 0);
		}
	}, [getSettingState]);

	const setSettings = useCallback(
		async (settings: { [key: string]: string }) => {
			try {
				setSettingsState.pending();

				await settingApi.setSettings(settings);

				setSettingsState.done();
			} catch (error: any) {
				setSettingsState.error(error?.response?.status ?? 0);
			}
		},
		[setSettingsState]
	);

	const setCourseListDisplayMode = useCallback(
		(courseListDisplayMode: "list" | "calendar") => {
			setCourseListDisplayModeState(courseListDisplayMode);
		},
		[]
	);

	return {
		getSettingState: toRequestResult(getSettingState),
		setSettingsState: toRequestResult(setSettingsState),
		settings,
		getSetting,
		setSettings,
		courseListDisplayMode: courseListDisplayModeState,
		setCourseListDisplayMode,
	};
};

export const SettingStore = createContainer(useSettingStore);
