import axios from "axios";

import { API_URL, postRequestConfig } from "./config";

interface SettingApi {
	setSettings(settings: { [key: string]: string }): Promise<void>;
	getSettings(): Promise<{ [key: string]: string }>;
}

export const settingApi: SettingApi = {
	async setSettings(settings: { [key: string]: string }) {
		await axios.patch(`${API_URL}/settings`, settings, postRequestConfig);
	},

	async getSettings() {
		const response = await axios(`${API_URL}/settings`);
		return response.data;
	},
};
