import React, { FC, useEffect } from "react";
import { useSnackbar } from "notistack";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";

import { LoadingSnackBar, PaymentInfo } from "../components";
import { PersonStore, State } from "../stores";
import { PaymentStore } from "../stores/paymentStore";

const messages = defineMessages({
	failedToLoad: {
		id: "paymentView.failedToLoad",
		defaultMessage: "Failed to load payments!",
	},
	savedMessage: {
		id: "paymentView.savedMessage",
		defaultMessage: "Modifications saved!",
	},
});

export const PaymentView: FC = () => {
	const history = useHistory();
	const { personList, listPersons } = PersonStore.useContainer();
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();
	const {
		getPaymentsState,
		paymentsForPerson,
		getPayments,
		signPaidMonthsState,
		signPaidMonths,
	} = PaymentStore.useContainer();
	const { id } = useParams<{ id?: string }>();
	const validIds = personList.map((person) => person.id);

	useEffect(() => {
		const personId = Number(id);
		getPayments(personId);
	}, [getPayments, id]);

	useEffect(() => {
		// if someone types invalid id in url
		if (personList.length === 0) {
			listPersons();
		}
	}, [id, listPersons, personList.length]);

	useEffect(() => {
		if (getPaymentsState.state === State.ERROR) {
			enqueueSnackbar(formatMessage(messages.failedToLoad), {
				variant: "error",
			});
		}
	}, [enqueueSnackbar, formatMessage, getPaymentsState]);

	useEffect(() => {
		if (signPaidMonthsState.state === State.DONE) {
			enqueueSnackbar(formatMessage(messages.savedMessage), {
				variant: "success",
			});
			const personId = Number(id);
			getPayments(personId);
			signPaidMonthsState.reset();
		}
	}, [enqueueSnackbar, formatMessage, getPayments, id, signPaidMonthsState]);

	if (!validIds.includes(Number(id)) && validIds.length > 0) {
		history.push("/persons");
	}

	return getPaymentsState.state === State.DONE ? (
		<PaymentInfo
			payments={paymentsForPerson}
			saveEditedPayments={signPaidMonths}
		/>
	) : (
		<LoadingSnackBar />
	);
};
