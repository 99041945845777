import React, { FC, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead,
	Button,
	Tooltip,
	Chip,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

import { ParticipationInfoDto, Attendance, PresenceEnum } from "../../types";
import { listStyles } from "./listStyle";

const messages = defineMessages({
	participants: {
		id: "attendance.participants",
		defaultMessage: "Participants",
	},
	presence: {
		id: "attendance.presence",
		defaultMessage: "Presence",
	},
	recuperating: {
		id: "attendance.recuperating",
		defaultMessage: "Replacement",
	},
	save: {
		id: "attendance.save",
		defaultMessage: "Save",
	},
	signedAbsence: {
		id: "attendance.signedAbsence",
		defaultMessage: "This absence is forecasted!",
	},
});

type Props = {
	participants: ParticipationInfoDto[];
	saveAttendance: (presence: Attendance[]) => void;
};

export const AttendanceList: FC<Props> = ({ participants, saveAttendance }) => {
	const classes = listStyles();
	const { formatMessage } = useIntl();
	const [presence, setPresence] = useState(() => {
		const initialValue: Attendance[] = [];

		participants.forEach((participant) => {
			if (participant.isThere === PresenceEnum.Initial) {
				initialValue.push({
					participationInfoId: participant.id,
					presenceType: PresenceEnum.Absent,
				});
			}
		});
		return initialValue;
	});

	const [checked, setChecked] = useState(() => {
		const initialValue: { [key: number]: boolean | undefined } = {};

		participants.forEach((participant) => {
			if (participant.isThere === PresenceEnum.Present) {
				initialValue[participant.id] = true;
			} else if (
				participant.isThere === PresenceEnum.Absent ||
				participant.isThere === PresenceEnum.Initial
			) {
				initialValue[participant.id] = false;
			} else {
				initialValue[participant.id] = undefined;
			}
		});
		return initialValue;
	});

	const handleChange = (event: any) => {
		setChecked({ ...checked, [event.target.value]: event.target.checked });
		let newArray = [...presence]; // copying the old datas array

		if (
			newArray.some(
				(attendance) =>
					attendance.participationInfoId ===
					parseInt(event.target.value)
			)
		) {
			newArray.map((attendance, index) => {
				if (
					attendance.participationInfoId ===
					parseInt(event.target.value)
				) {
					newArray[index].presenceType = event.target.checked
						? PresenceEnum.Present
						: PresenceEnum.Absent;
				}
			});
		} else {
			newArray.push({
				participationInfoId: parseInt(event.target.value),
				presenceType: event.target.checked
					? PresenceEnum.Present
					: PresenceEnum.Absent,
			});
		}
		setPresence(newArray);
	};

	const handleSave = () => {
		saveAttendance(presence);
		setPresence([]);
	};

	return (
		<div className={classes.paper}>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell> </TableCell>
						<TableCell className={classes.headerCell}>
							{formatMessage(messages.participants)}
						</TableCell>
						<TableCell align="right" className={classes.headerCell}>
							{formatMessage(messages.presence)}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{participants.map((participant) => (
						<TableRow key={participant.id}>
							<TableCell align="center">
								<PersonIcon />
							</TableCell>
							<TableCell component="th" scope="row">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyItems: "center",
									}}
								>
									{participant.person.name}
									{participant.replacement ? (
										<Chip
											size="medium"
											variant="outlined"
											label={formatMessage(
												messages.recuperating
											)}
										/>
									) : null}
								</div>
							</TableCell>
							<TableCell align="center">
								{checked[participant.id] == undefined ? (
									<Tooltip
										title={formatMessage(
											messages.signedAbsence
										)}
										aria-label={formatMessage(
											messages.signedAbsence
										)}
										placement="top-end"
									>
										<div>
											<Checkbox disabled />
										</div>
									</Tooltip>
								) : (
									<Checkbox
										checked={checked[participant.id]}
										value={participant.id}
										onChange={handleChange}
										inputProps={{
											"aria-label": "controlled",
										}}
									/>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<div className={classes.saveAttendance}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSave}
				>
					{formatMessage(messages.save)}
				</Button>
			</div>
		</div>
	);
};
