import React, { FC } from "react";
import { Card, Typography, IconButton } from "@material-ui/core";
import { useIntl } from "react-intl";
import PoolIcon from "@material-ui/icons/Pool";

import { listStyles } from "./listStyle";
import { Training } from "../../types";

type Props = {
	training?: Training;
};

export const TrainingCard: FC<Props> = ({ training }) => {
	const classes = listStyles();
	const { formatDate } = useIntl();

	return (
		<div className={classes.root}>
			<Card className={classes.card}>
				<IconButton className={classes.cardsIcon}>
					<PoolIcon />
				</IconButton>
				<Typography
					className={classes.cardsInfo}
					variant="caption"
					component="div"
				>
					{training?.course.type}
				</Typography>

				<Typography
					className={classes.cardsInfo}
					variant="caption"
					component="div"
				>
					{formatDate(training?.date)}
				</Typography>
				<Typography
					className={classes.cardsInfo}
					variant="caption"
					component="div"
				>
					{training?.course.startHour}
				</Typography>
			</Card>
		</div>
	);
};
