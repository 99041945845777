import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { personApi } from "../api/personApi";
import {
	Absence,
	Person,
	PersonCreationDto,
	PersonDto,
	PersonEditDto,
	Training,
} from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface PersonStoreResult {
	addPersonState: RequestResult;
	addPerson(person: PersonCreationDto): Promise<void>;
	listPersonsState: RequestResult;
	personList: PersonDto[];
	listPersons(): Promise<void>;
	getPersonDetailsState: RequestResult;
	currentPerson?: Person;
	getPersonDetails(id: number): Promise<void>;
	editPersonState: RequestResult;
	editPerson(id: number, person: PersonEditDto): Promise<void>;
	listTrainingsForPersonState: RequestResult;
	listTrainingsForPerson(personId: number): Promise<void>;
	trainingListForPerson: Training[];
	listAbsencesForPersonState: RequestResult;
	absenceListForPerson: Absence[];
	listAbsencesForPerson(personId: number): Promise<void>;
}

const usePersonStore = (): PersonStoreResult => {
	const addPersonState = useRequestState();
	const listPersonsState = useRequestState();
	const getPersonDetailsState = useRequestState();
	const editPersonState = useRequestState();
	const listAbsencesForPersonState = useRequestState();

	const [personList, setPersonList] = useState<PersonDto[]>([]);
	const [currentPerson, setCurrentPerson] = useState<Person | undefined>(
		undefined
	);
	const listTrainingsForPersonState = useRequestState();
	const [trainingListForPerson, setTrainingListForPerson] = useState<
		Training[]
	>([]);
	const [absenceListForPerson, setAbsenceListForPerson] = useState<Absence[]>(
		[]
	);

	const addPerson = useCallback(
		async (person: PersonCreationDto) => {
			try {
				addPersonState.pending();

				await personApi.postNewPerson(person);

				addPersonState.done();
			} catch (error: any) {
				addPersonState.error(error?.response?.status ?? 0);
			}
		},
		[addPersonState]
	);

	const listPersons = useCallback(async () => {
		try {
			listPersonsState.pending();

			const persons = await personApi.findAllPerson();
			setPersonList(persons);

			listPersonsState.done();
		} catch (error: any) {
			listPersonsState.error(error?.response?.status ?? 0);
		}
	}, [listPersonsState]);

	const getPersonDetails = useCallback(
		async (id: number) => {
			try {
				getPersonDetailsState.pending();

				const person = await personApi.findDetailedInformation(id);
				setCurrentPerson(person);

				getPersonDetailsState.done();
			} catch (error: any) {
				getPersonDetailsState.error(error?.response?.status ?? 0);
			}
		},
		[getPersonDetailsState]
	);

	const editPerson = useCallback(
		async (id: number, person: PersonEditDto) => {
			try {
				editPersonState.pending();

				await personApi.editPerson(id, person);

				editPersonState.done();
			} catch (error: any) {
				editPersonState.error(error?.response?.status ?? 0);
			}
		},
		[editPersonState]
	);

	const listTrainingsForPerson = useCallback(
		async (personId: number) => {
			try {
				listTrainingsForPersonState.pending();

				let result = await personApi.listAllTrainingsForPerson(
					personId
				);
				setTrainingListForPerson(result);

				listTrainingsForPersonState.done();
			} catch (error: any) {
				listTrainingsForPersonState.error(error?.response?.status ?? 0);
			}
		},
		[listTrainingsForPersonState]
	);

	const listAbsencesForPerson = useCallback(
		async (personId: number) => {
			try {
				listAbsencesForPersonState.pending();

				const absences = await personApi.findAllAbsencesForPerson(
					personId
				);
				setAbsenceListForPerson(absences);

				listAbsencesForPersonState.done();
			} catch (error: any) {
				listAbsencesForPersonState.error(error?.response?.status ?? 0);
			}
		},
		[listAbsencesForPersonState]
	);

	return {
		addPersonState: toRequestResult(addPersonState),
		listPersonsState: toRequestResult(listPersonsState),
		getPersonDetailsState: toRequestResult(getPersonDetailsState),
		editPersonState: toRequestResult(editPersonState),
		listTrainingsForPersonState: toRequestResult(
			listTrainingsForPersonState
		),
		listAbsencesForPersonState: toRequestResult(listAbsencesForPersonState),
		personList,
		currentPerson,
		trainingListForPerson,
		absenceListForPerson,
		addPerson,
		listPersons,
		getPersonDetails,
		editPerson,
		listTrainingsForPerson,
		listAbsencesForPerson,
	};
};

export const PersonStore = createContainer(usePersonStore);
