import { createTheme, makeStyles } from "@material-ui/core";

export const theme = createTheme({
	palette: {
		primary: {
			light: "#63a4ff",
			main: "#1976d2",
			dark: "#004ba0",
		},
		secondary: {
			light: "#9a67ea",
			main: "#673ab7",
			dark: "#320b86",
		},
	},
});

export const useStyles = makeStyles(() => ({
	header: {
		height: "75px",
		position: "fixed",
		paddingRight: "3%",
		backgroundColor: theme.palette.primary.dark,
		"@media (max-width: 900px)": {
			paddingLeft: 0,
		},
	},

	logo: {
		fontWeight: 600,
		color: "#FFFEFE",
		textAlign: "left",
	},

	menuButton: {
		fontWeight: 700,
		size: "18px",
		marginLeft: "38px",
	},

	toolbar: {
		display: "flex",
		justifyContent: "space-between",
		backgroundColor: theme.palette.primary.dark,
		width: "100%",
	},

	toolbar2: {
		backgroundColor: theme.palette.primary.dark,
		width: "100%",
	},

	drawerContainer: {
		backgroundColor: theme.palette.primary.dark,
		padding: "20px 30px",
		height: "100%",
	},

	buttons: {
		color: "white",
		width: "160px",
		height: "75px",
		textAlign: "center",
		"&:hover": {
			backgroundColor: theme.palette.secondary.dark,
		},
	},

	icon: {
		margin: "10px",
		alignItems: "center",
	},

	mainLogo: {
		fontSize: "100px",
		alignItems: "center",
	},

	accordionNote: {
		borderLeft: "1px solid black",
		color: theme.palette.secondary.dark,
		paddingLeft: 15,
	},
}));
