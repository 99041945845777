import React, { FC } from "react";
import { IntlProvider } from "react-intl";

import { LanguageStore } from "../stores/languageStore";

export const IntlLanguageProvider: FC = ({ children }) => {
	const languageStore = LanguageStore.useContainer();
	return (
		<IntlProvider
			messages={languageStore.messages}
			locale={languageStore.locale}
		>
			{children}
		</IntlProvider>
	);
};
