import React, { FC } from "react";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { useIntl } from "react-intl";

import { CalendarView, LightToolbar, SmallSwitch } from "../components";
import { CourseReducedDto } from "../types";
import { LanguageStore, SettingStore } from "../stores";
import { frequentMessages } from "../locales";

import "./pages.css";

type Props = {
	courseList: CourseReducedDto[];
};

export const CalendarMode: FC<Props> = ({ courseList }) => {
	const { locale } = LanguageStore.useContainer();
	const { formatMessage } = useIntl();
	let courses: AppointmentModel[] = courseList.map(
		(course: CourseReducedDto) => {
			let startHour = course.startHour.split(":");
			let endHour = course.endHour.split(":");
			return {
				title: course.type,
				startDate: new Date(
					2021,
					2,
					course.day,
					parseInt(startHour[0]),
					parseInt(startHour[1])
				),
				endDate: new Date(
					2021,
					2,
					course.day,
					parseInt(endHour[0]),
					parseInt(endHour[1])
				),
				id: course.id,
			};
		}
	);
	const { courseListDisplayMode, setCourseListDisplayMode } =
		SettingStore.useContainer();

	return (
		<div className="mainDiv">
			<LightToolbar title="Calendar">
				<div style={{ marginLeft: "auto" }}>
					<SmallSwitch
						checked={courseListDisplayMode === "calendar"}
						firstOption={formatMessage(frequentMessages.list)}
						secondOption={formatMessage(frequentMessages.calendar)}
						action={() => {
							courseListDisplayMode === "calendar"
								? setCourseListDisplayMode("list")
								: setCourseListDisplayMode("calendar");
						}}
					/>
				</div>
			</LightToolbar>
			<CalendarView
				appointmentModel={courses}
				showDays={false}
				locale={locale}
				urlPrefix="/courses"
			/>
		</div>
	);
};
