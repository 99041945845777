import axios from "axios";

import { ParticipationInfoDto, Attendance } from "../types";
import { API_URL, postRequestConfig } from "./config";

interface AttendanceApi {
	getParticipationInfo(trainingId: number): Promise<ParticipationInfoDto[]>;
	setPresence(presence: Attendance[], trainingId: number): Promise<void>;
}

export const attendanceApi: AttendanceApi = {
	async getParticipationInfo(trainingId: number) {
		const response = await axios(
			`${API_URL}/trainings/${trainingId}/attendance`
		);
		return response.data;
	},
	async setPresence(presence: Attendance[], trainingId: number) {
		await axios.patch(
			`${API_URL}/trainings/${trainingId}/attendance`,
			presence,
			postRequestConfig
		);
	},
};
