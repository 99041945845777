import React, { FC, useEffect } from "react";

import { CourseTypeCreationForm } from "../components";
import { CourseTypeStore, State } from "../stores";

export const CourseTypeCreation: FC = () => {
	const courseTypeStore = CourseTypeStore.useContainer();
	const { courseTypeCreationState, getCourseTypes } = courseTypeStore;

	// refresh list of types when a new type is added
	useEffect(() => {
		if (courseTypeCreationState.state === State.DONE) {
			getCourseTypes();
		}
	}, [courseTypeCreationState.state, getCourseTypes]);

	return (
		<CourseTypeCreationForm
			onSubmit={courseTypeStore.createCourseType}
			requestResult={courseTypeStore.courseTypeCreationState}
		/>
	);
};
