import { FormikProps } from "formik";

import {
	Course,
	CourseReducedDto,
	EnrollmentDto,
	PersonDto,
	UserDto,
	WaitingListElementDto,
} from "../types";

export function timeCompare(
	end: string | undefined,
	testContext: any
): boolean {
	const start = testContext.parent.startHour;
	let endHour = 0;
	let endMinute = 0;
	let startHour = 0;
	let startMinute = 0;

	if (typeof end === "undefined" || typeof start === "undefined") {
		return false;
	}

	endHour = Number.parseInt(end.split(":")[0], 10);
	endMinute = Number.parseInt(end.split(":")[1], 10);
	startHour = Number.parseInt(start.split(":")[0], 10);
	startMinute = Number.parseInt(start.split(":")[1], 10);

	const date1 = new Date(2021, 1, 1, startHour, startMinute, 0);

	const date2 = new Date(2021, 1, 1, endHour, endMinute, 0);

	return date1 < date2;
}

export const shouldShowErrorMessage = (
	formikProps: FormikProps<any>,
	key: string
) => {
	const errorKeys = Object.keys(formikProps.errors);
	const touchedKeys = Object.keys(formikProps.touched);

	return errorKeys.includes(key) && touchedKeys.includes(key);
};

// get rid of timestamps when send a date
export const withoutTime = (oldDate: string) => {
	const date = new Date(oldDate);
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	return date.toString();
};

// add timestamps to dates
export const withTime = (date: string, timestamp: string) => {
	const parts = date.split("T");
	return `${parts[0]}T${timestamp}`;
};

export function getNotEnrolledPersons(
	persons: PersonDto[],
	enrolledPersons: PersonDto[],
	personsOnWaitingList: WaitingListElementDto[]
) {
	let notEnrolledPersons: PersonDto[] = [];
	let enrolled: boolean;
	persons.forEach((person) => {
		enrolled = false;
		enrolledPersons.forEach((enrolledPerson) => {
			if (enrolledPerson.id === person.id) {
				enrolled = true;
			}
		});
		personsOnWaitingList.forEach((personOnWaitingList) => {
			if (person.id === personOnWaitingList.personId) {
				enrolled = true;
			}
		});
		if (!enrolled) {
			notEnrolledPersons.push(person);
		}
	});
	return notEnrolledPersons;
}

export const findPendingEnrollments = (
	enrollments: EnrollmentDto[],
	course?: CourseReducedDto | Course
): EnrollmentDto[] =>
	enrollments.filter((e) => e.courseId == course?.id && !e.accepted);

export const findAcceptedEnrollments = (
	enrollments: EnrollmentDto[],
	course?: CourseReducedDto | Course
): EnrollmentDto[] =>
	enrollments.filter((e) => e.courseId == course?.id && e.accepted);

export const getMonthList = (
	locale = "en",
	notation:
		| "short"
		| "numeric"
		| "long"
		| "2-digit"
		| "narrow"
		| undefined = "long"
) =>
	Array.from(Array(12).keys(), (key) =>
		Intl.DateTimeFormat(locale, {
			month: notation,
		}).format(new Date(0, key))
	);

export const findWaitingPersons = (
	waitingList: WaitingListElementDto[],
	personList: PersonDto[],
	currentUser: UserDto | undefined
): PersonDto[] | undefined => {
	if (currentUser === undefined) {
		return undefined;
	} else {
		let persons: PersonDto[] = [];
		for (let i = 0; i < waitingList.length; i++) {
			for (let j = 0; j < personList.length; j++) {
				if (waitingList[i].personId == personList[j].id) {
					persons.push(personList[j]);
				}
			}
		}
		return persons;
	}
};
