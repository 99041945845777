import React, { FC, useEffect } from "react";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import { frequentMessages } from "../../locales";
import { State, UserStore } from "../../stores";

type Props = {
	handleCloseAccount: () => void;
	anchorElement: HTMLButtonElement | null;
	isUserLoggedIn: boolean;
};

export const AccountMenu: FC<Props> = ({
	handleCloseAccount,
	anchorElement,
	isUserLoggedIn,
}) => {
	const { formatMessage } = useIntl();
	const { logoutUser, logoutUserState, introspectUser } =
		UserStore.useContainer();
	const history = useHistory();

	const logOut = () => {
		logoutUser();
		handleCloseAccount();
	};

	const registrationMenuItem = (
		<MenuItem
			key="registrationMenuItem"
			onClick={handleCloseAccount}
			component={Link}
			to="/registration"
		>
			{formatMessage(frequentMessages.registration)}
		</MenuItem>
	);

	const loginMenuItem = (
		<MenuItem
			key="loginMenuItem"
			onClick={handleCloseAccount}
			component={Link}
			to="/login"
		>
			{formatMessage(frequentMessages.login)}
		</MenuItem>
	);

	const logoutMenuItem = (
		<MenuItem key="logoutMenuItem" onClick={logOut} component={Typography}>
			{formatMessage(frequentMessages.logout)}
		</MenuItem>
	);

	useEffect(() => {
		if (logoutUserState.state === State.DONE) {
			introspectUser();
			logoutUserState.reset();
			history.push("/login");
		}
	}, [history, introspectUser, logoutUserState, logoutUserState.state]);

	return (
		<Menu
			anchorEl={anchorElement}
			keepMounted
			open={Boolean(anchorElement)}
			onClose={handleCloseAccount}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			{isUserLoggedIn
				? logoutMenuItem
				: [loginMenuItem, registrationMenuItem]}
		</Menu>
	);
};
