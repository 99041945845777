import React, { FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@material-ui/core";
import PoolIcon from "@material-ui/icons/Pool";
import { Link } from "react-router-dom";

import { CourseReducedDto } from "../../types";
import { listStyles } from "./listStyle";
import "./dataDisplay.css";
import { frequentMessages } from "../../locales";
import { LightToolbar, SmallSwitch } from "..";
import { SettingStore } from "../../stores";

type Props = {
	courses: CourseReducedDto[];
};

const messages = defineMessages({
	courses: {
		id: "courseList.courses",
		defaultMessage: "Courses",
	},

	enrolled: {
		id: "courseList.enrolled",
		defaultMessage: "Enrolled: ",
	},
});

export const CourseList: FC<Props> = ({ courses }) => {
	const classes = listStyles();
	const { formatMessage } = useIntl();
	let days = [
		formatMessage(frequentMessages.monday),
		formatMessage(frequentMessages.tuesday),
		formatMessage(frequentMessages.wednesday),
		formatMessage(frequentMessages.thursday),
		formatMessage(frequentMessages.friday),
		formatMessage(frequentMessages.saturday),
		formatMessage(frequentMessages.sunday),
	];
	const { courseListDisplayMode, setCourseListDisplayMode } =
		SettingStore.useContainer();

	return (
		<div className={classes.root}>
			<div className={classes.fitToolbar}>
				<LightToolbar title={formatMessage(messages.courses)}>
					<div style={{ marginLeft: "auto" }}>
						<SmallSwitch
							checked={courseListDisplayMode === "calendar"}
							firstOption={formatMessage(frequentMessages.list)}
							secondOption={formatMessage(
								frequentMessages.calendar
							)}
							action={() => {
								courseListDisplayMode === "calendar"
									? setCourseListDisplayMode("list")
									: setCourseListDisplayMode("calendar");
							}}
						/>
					</div>
				</LightToolbar>
			</div>
			<List className={classes.list}>
				{courses.map((course) => (
					<ListItem
						dense
						button
						key={course.id}
						component={Link}
						to={`/courses/${course.id}`}
					>
						<ListItemIcon>
							<PoolIcon />
						</ListItemIcon>
						<ListItemText
							disableTypography
							primary={course.type}
							secondary={
								<div>
									<Typography
										className="additionalInfo"
										variant="caption"
										component="div"
									>
										{days[course.day - 1]}{" "}
										{course.startHour} - {course.endHour}
									</Typography>
									<Typography
										className="additionalInfo"
										variant="caption"
										component="div"
									>
										{formatMessage(messages.enrolled)}
										{course.nrOfEnrolledPersons}/
										{course.participationLimit}
									</Typography>
								</div>
							}
						/>
					</ListItem>
				))}
			</List>
		</div>
	);
};
