import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { ParticipationInfoDto, Attendance } from "../types";
import { attendanceApi } from "../api/attendanceApi";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface AttendanceStoreResult {
	listParticipationInfosState: RequestResult;
	setPresenceState: RequestResult;

	participationInfoList: ParticipationInfoDto[];

	listParticipationInfo(trainingId: number): Promise<void>;
	setPresence(presence: Attendance[], trainingId: number): Promise<void>;
}

const useParticipationInfoStore = (): AttendanceStoreResult => {
	const listParticipationInfosState = useRequestState();
	const setPresenceState = useRequestState();
	const [participationInfoList, setParticipationInfoList] = useState<
		ParticipationInfoDto[]
	>([]);

	const listParticipationInfo = useCallback(
		async (trainingId: number) => {
			try {
				listParticipationInfosState.pending();

				const participationInfo =
					await attendanceApi.getParticipationInfo(trainingId);
				setParticipationInfoList(participationInfo);

				listParticipationInfosState.done();
			} catch (error: any) {
				listParticipationInfosState.error(error?.response?.status ?? 0);
			}
		},
		[listParticipationInfosState]
	);

	const setPresence = useCallback(
		async (presence: Attendance[], trainingId: number) => {
			try {
				setPresenceState.pending();

				await attendanceApi.setPresence(presence, trainingId);

				setPresenceState.done();
			} catch (error: any) {
				setPresenceState.error(error?.response?.status ?? 0);
			}
		},
		[setPresenceState]
	);

	return {
		listParticipationInfosState: toRequestResult(
			listParticipationInfosState
		),
		setPresenceState: toRequestResult(setPresenceState),
		participationInfoList,
		listParticipationInfo,
		setPresence,
	};
};

export const AttendanceStore = createContainer(useParticipationInfoStore);
