import React, { FC } from "react";
import {
	Button,
	MenuItem,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as yup from "yup";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import PoolIcon from "@material-ui/icons/Pool";

import { timeCompare, shouldShowErrorMessage } from "../utils";
import { CourseCreationDto, CourseType, DateRange } from "../../types";
import { RequestResult } from "../../stores";
import { frequentMessages } from "../../locales";
import { DatePicker, SubmitProgress } from ".";
import { useStyles } from "../../assets";
import "./formStyle.css";

const initialValues: CourseCreationDto = {
	type: "",
	day: 1,
	startHour: "",
	endHour: "",
	startDate: "",
	endDate: "",
	participationLimit: 0,
	extraPlaces: 0,
};

const messages = defineMessages({
	createCourse: {
		id: "courseCreation.createCourse",
		defaultMessage: "Create Course",
	},

	endTime: {
		id: "courseCreation.EndTime",
		defaultMessage: "End Time",
	},

	startDate: {
		id: "courseCreation.StartDate",
		defaultMessage: "Start Date",
	},

	endDate: {
		id: "courseCreation.EndDate",
		defaultMessage: "End Date",
	},

	maxParticipants: {
		id: "courseCreation.MaxParticipants",
		defaultMessage: "Maximum Participants",
	},

	extraPlaces: {
		id: "courseCreation.ExtraPlaces",
		defaultMessage: "Extra Places",
	},

	typeRequired: {
		id: "courseCreation.typeRequired",
		defaultMessage: "The type is required",
	},

	dayRequired: {
		id: "courseCreation.dayRequired",
		defaultMessage: "The day is required",
	},

	startHourRequired: {
		id: "courseCreation.startHourRequired",
		defaultMessage: "The start hour is required",
	},

	endHourRequired: {
		id: "courseCreation.endHourRequired",
		defaultMessage: "The end hour is required",
	},

	startDateRequired: {
		id: "courseCreation.startDateRequired",
		defaultMessage: "The start date is required",
	},

	endDateRequired: {
		id: "courseCreation.endDateRequired",
		defaultMessage: "The end date is required",
	},

	participationLimitRequired: {
		id: "courseCreation.participationLimitRequired",
		defaultMessage: "The participation limit field is required",
	},

	minParticipation: {
		id: "courseCreation.minParticipation",
		defaultMessage: "The participation limit must be a positive number.",
	},

	extraPlacesRequired: {
		id: "courseCreation.extraPlacesRequired",
		defaultMessage: "The extra places field is required",
	},

	minExtraPlaces: {
		id: "courseCreation.minExtraPlaces",
		defaultMessage: "The extra places must be a positive number.",
	},

	wrongTime: {
		id: "courseCreation.wrongTime",
		defaultMessage: "End time > Start time!",
	},

	wrongDate: {
		id: "courseCreation.wrongDate",
		defaultMessage: "End date can't be before start date.",
	},

	hourFormat: {
		id: "courseCreation.hourFormat",
		defaultMessage: "Invalid hour format.",
	},

	submitCourse: {
		id: "courseCreation.submit",
		defaultMessage: "Submit",
	},
});

type Props = {
	onSubmit: (course: CourseCreationDto) => void;
	requestResult: RequestResult;
	types: CourseType[];
};

export const CourseCreationForm: FC<Props> = ({
	onSubmit,
	requestResult,
	types,
}) => {
	const { formatMessage, formatDate } = useIntl();
	const classes = useStyles();

	const validationSchema = yup.object().shape({
		type: yup.string().required(formatMessage(messages.typeRequired)),
		day: yup.string().required(formatMessage(messages.dayRequired)),
		startHour: yup
			.string()
			.required(formatMessage(messages.startHourRequired))
			.matches(/^[0-9]{2}:[0-9]{2}$/, formatMessage(messages.hourFormat)),
		endHour: yup
			.string()
			.required(formatMessage(messages.endHourRequired))
			.matches(/^[0-9]{2}:[0-9]{2}$/, formatMessage(messages.hourFormat))
			.test("greater", formatMessage(messages.wrongTime), timeCompare),
		startDate: yup
			.date()
			.required(formatMessage(messages.startDateRequired)),
		endDate: yup
			.date()
			.required(formatMessage(messages.endDateRequired))
			.min(yup.ref("startDate"), formatMessage(messages.wrongDate)),
		participationLimit: yup
			.number()
			.required(formatMessage(messages.participationLimitRequired))
			.min(0, formatMessage(messages.minParticipation)),
		extraPlaces: yup
			.number()
			.required("Required")
			.min(0, formatMessage(messages.minExtraPlaces)),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				values.startDate = formatDate(values.startDate);
				values.endDate = formatDate(values.endDate);
				onSubmit(values);
			}}
		>
			{(formikProps) => (
				<div className="formDiv">
					<Paper variant="elevation" className="paper">
						<Form className="inputContainer">
							<PoolIcon className={classes.mainLogo} />
							<Typography variant="h4">
								<FormattedMessage {...messages.createCourse} />
							</Typography>
							<div className="inputElement">
								<Field
									as={TextField}
									label={formatMessage(frequentMessages.type)}
									select
									id="type"
									type="text"
									name="type"
									color="primary"
									variant="outlined"
									onChange={formikProps.handleChange}
									error={shouldShowErrorMessage(
										formikProps,
										"type"
									)}
									className="wide"
								>
									{types.map((courseType) => (
										<MenuItem
											key={types.indexOf(courseType)}
											value={courseType.name}
										>
											{courseType.name}
										</MenuItem>
									))}
								</Field>
								<ErrorMessage name="type">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									select
									id="day"
									onChange={formikProps.handleChange}
									error={shouldShowErrorMessage(
										formikProps,
										"day"
									)}
									name="day"
									defaultValue="1"
									color="primary"
									className="wide"
									variant="outlined"
								>
									<MenuItem key="1" value="1">
										<FormattedMessage
											{...frequentMessages.monday}
										/>
									</MenuItem>

									<MenuItem key="2" value="2">
										<FormattedMessage
											{...frequentMessages.tuesday}
										/>
									</MenuItem>

									<MenuItem key="3" value="3">
										<FormattedMessage
											{...frequentMessages.wednesday}
										/>
									</MenuItem>

									<MenuItem key="4" value="4">
										<FormattedMessage
											{...frequentMessages.thursday}
										/>
									</MenuItem>

									<MenuItem key="5" value="5">
										<FormattedMessage
											{...frequentMessages.friday}
										/>
									</MenuItem>

									<MenuItem key="6" value="6">
										<FormattedMessage
											{...frequentMessages.saturday}
										/>
									</MenuItem>

									<MenuItem key="7" value="7">
										<FormattedMessage
											{...frequentMessages.sunday}
										/>
									</MenuItem>
								</Field>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									id="start"
									label={formatMessage(
										frequentMessages.startTime
									)}
									type="time"
									color="primary"
									variant="outlined"
									value={formikProps.values.startHour}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										step: 300,
									}}
									onChange={formikProps.handleChange}
									error={shouldShowErrorMessage(
										formikProps,
										"startHour"
									)}
									name="startHour"
									className="wide"
								/>
								<ErrorMessage name="startHour">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									id="end"
									label={formatMessage(messages.endTime)}
									type="time"
									color="primary"
									variant="outlined"
									value={formikProps.values.endHour}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										step: 300,
									}}
									onChange={formikProps.handleChange}
									error={shouldShowErrorMessage(
										formikProps,
										"endHour"
									)}
									className="wide"
									name="endHour"
								/>
								<ErrorMessage name="endHour">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<DatePicker
									label={formatMessage(messages.startDate)}
									value={formikProps.values.startDate}
									name="startDate"
									format={formatDate(
										formikProps.values.startDate
									)}
									onChange={(value) => {
										formikProps.setFieldValue(
											"startDate",
											value ? value : ""
										);
										formikProps.validateField("startDate");
									}}
									onBlur={formikProps.handleBlur}
									errorCheck={shouldShowErrorMessage(
										formikProps,
										"startDate"
									)}
									disableRange={DateRange.Past}
								/>
								<ErrorMessage name="startDate">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<DatePicker
									label={formatMessage(messages.endDate)}
									value={formikProps.values.endDate}
									name="endDate"
									format={formatDate(
										formikProps.values.endDate
									)}
									onChange={(value) => {
										formikProps.setFieldValue(
											"endDate",
											value ? value : ""
										);
										formikProps.validateField("endDate");
										formikProps.setFieldTouched(
											"endDate",
											true,
											false
										);
									}}
									onBlur={formikProps.handleBlur}
									errorCheck={shouldShowErrorMessage(
										formikProps,
										"endDate"
									)}
									disableRange={DateRange.Past}
								/>
								<ErrorMessage name="endDate">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									id="maxParticipants"
									label={formatMessage(
										messages.maxParticipants
									)}
									type="number"
									onChange={formikProps.handleChange}
									color="primary"
									variant="outlined"
									name="participationLimit"
									error={shouldShowErrorMessage(
										formikProps,
										"participationLimit"
									)}
									className="wide"
								/>
								<ErrorMessage name="participationLimit">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									id="extraPlaces"
									label={formatMessage(messages.extraPlaces)}
									type="number"
									onChange={formikProps.handleChange}
									color="primary"
									variant="outlined"
									className="wide"
									name="extraPlaces"
									error={shouldShowErrorMessage(
										formikProps,
										"extraPlaces"
									)}
								/>
								<ErrorMessage name="extraPlaces">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<Button
								type="submit"
								variant="contained"
								value="Submit"
								color="primary"
								disabled={!formikProps.isValid}
							>
								<FormattedMessage {...messages.submitCourse} />
							</Button>

							<SubmitProgress requestResult={requestResult} />
						</Form>
					</Paper>
				</div>
			)}
		</Formik>
	);
};
