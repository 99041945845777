import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { paymentApi } from "../api/paymentApi";
import { PaymentDto, PaymentSetterDto } from "../types/payment";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface PaymentStoreResult {
	getPaymentsState: RequestResult;
	paymentsForPerson: PaymentDto[];
	getPayments(personId: number): Promise<void>;

	signPaidMonthsState: RequestResult;
	signPaidMonths(monthsForPayment: PaymentSetterDto): Promise<void>;
}

const useRegistration = (): PaymentStoreResult => {
	const getPaymentsState = useRequestState();
	const [paymentsForPerson, setPaymentsForPerson] = useState<PaymentDto[]>(
		[]
	);
	const signPaidMonthsState = useRequestState();

	const getPayments = useCallback(
		async (personId: number) => {
			try {
				getPaymentsState.pending();

				const payments = await paymentApi.getPaymentsByPerson(personId);
				setPaymentsForPerson(payments);

				getPaymentsState.done();
			} catch (error: any) {
				getPaymentsState.error(error?.response?.status ?? 0);
			}
		},
		[getPaymentsState]
	);

	const signPaidMonths = useCallback(
		async (monthsForPayment: PaymentSetterDto) => {
			try {
				signPaidMonthsState.pending();

				await paymentApi.signPaidMonths(monthsForPayment);

				signPaidMonthsState.done();
			} catch (error: any) {
				signPaidMonthsState.error(error?.response.status ?? 0);
			}
		},
		[signPaidMonthsState]
	);

	return {
		getPaymentsState: toRequestResult(getPaymentsState),
		paymentsForPerson,
		getPayments,
		signPaidMonthsState: toRequestResult(signPaidMonthsState),
		signPaidMonths,
	};
};

export const PaymentStore = createContainer(useRegistration);
