import React, { FC, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
	Button,
	ButtonGroup,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link, useHistory } from "react-router-dom";

import { listStyles } from "./listStyle";
import { PersonDto, Role, UserDto } from "../../types";
import { frequentMessages } from "../../locales";
import { SearchField } from ".";
import { LightToolbar } from "..";

const messages = defineMessages({
	persons: {
		id: "personList.persons",
		defaultMessage: "Persons",
	},

	addedBy: {
		id: "personList.addedBy",
		defaultMessage: "Added by",
	},

	searchByCreator: {
		id: "personList.searchByCreator",
		defaultMessage: "Search by creator...",
	},

	absences: {
		id: "personList.absences",
		defaultMessage: "Absences",
	},
});

type Props = {
	persons: PersonDto[];
	currentUser: UserDto;
};

export const PersonList: FC<Props> = ({ persons, currentUser }) => {
	const classes = listStyles();
	const history = useHistory();
	const { formatMessage, formatDate } = useIntl();

	const [actualPersonList, setActualPersonList] = useState(persons);
	const updatePersons = (newPersons: PersonDto[]) =>
		setActualPersonList(newPersons);

	const normalInfo = (person: PersonDto) => (
		<Typography
			className="additionalInfo"
			variant="caption"
			component="div"
		>{`${formatMessage(frequentMessages.birthDate)}: ${formatDate(
			person.birthDate
		)}`}</Typography>
	);

	const extendedInfo = (person: PersonDto) => (
		<>
			{normalInfo(person)}

			<Typography
				className="additionalInfo"
				variant="caption"
				component="div"
			>
				{`${formatMessage(messages.addedBy)}: ${person.creatorName}`}
			</Typography>
		</>
	);

	return (
		<div className={classes.root}>
			<div className={classes.fitToolbar}>
				<LightToolbar title={formatMessage(messages.persons)}>
					{currentUser.role === Role.ADMIN ? (
						<SearchField
							placeholder={formatMessage(
								messages.searchByCreator
							)}
							data={persons}
							attribute={(person: PersonDto) =>
								person.creatorName
							}
							setResult={updatePersons}
						/>
					) : null}
				</LightToolbar>
			</div>

			<List className={classes.list}>
				{actualPersonList.map((person: PersonDto) => (
					<ListItem
						dense
						button
						key={actualPersonList.indexOf(person)}
						onClick={() => {
							history.push(`/persons/${person.id}/payments`);
						}}
					>
						<ListItemIcon>
							<PersonIcon />
						</ListItemIcon>
						<ListItemText
							disableTypography
							primary={person.name}
							secondary={
								currentUser.role === Role.ADMIN
									? extendedInfo(person)
									: normalInfo(person)
							}
						/>
						{currentUser.role === Role.NORMAL ? (
							<ListItemSecondaryAction>
								<ButtonGroup
									disableElevation
									variant="contained"
									color="primary"
								>
									<Button
										component={Link}
										to={`/persons/${person.id}/edit`}
									>
										<FormattedMessage
											{...frequentMessages.edit}
										/>
									</Button>
									<Button
										component={Link}
										to={`/persons/${person.id}/absences`}
									>
										<FormattedMessage
											{...messages.absences}
										/>
									</Button>
								</ButtonGroup>
							</ListItemSecondaryAction>
						) : null}
					</ListItem>
				))}
			</List>
			{currentUser.role == Role.NORMAL ? (
				<Link to="/persons/add" style={{ textDecoration: "none" }}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddCircleIcon />}
						className={classes.button}
					>
						{formatMessage(frequentMessages.newPerson)}
					</Button>
				</Link>
			) : null}
		</div>
	);
};
