import React, { FC, useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";

import { State, TrainingStore } from "../stores";

const messages = defineMessages({
	cancel: {
		id: "cancelDialog.cancel",
		defaultMessage: "Cancel",
	},
	cancelledSuccesfully: {
		id: "cancelDialog.okMessage",
		defaultMessage: "Training cancelled succesfully",
	},
	content: {
		id: "cancelDialog.content",
		defaultMessage:
			"Clicking the OK button will cancel this training. Please describe why the training will not be held!",
	},
	errorMessage: {
		id: "cancelDialog.errorMessage",
		defaultMessage: "Oops! An error occoured...",
	},
	missingReason: {
		id: "cancelDialog.missingReason",
		defaultMessage: "Error! Missing reason!",
	},
	reason: {
		id: "cancelDialog.reason",
		defaultMessage: "Reason",
	},
	title: {
		id: "cancelDialog.title",
		defaultMessage: "Cancel Training",
	},
});

type Props = {
	open: boolean;
	trainingId: number;
	handleClose: () => void;
};

export const CancelDialog: FC<Props> = ({ open, trainingId, handleClose }) => {
	const [reason, setReason] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();
	const { cancelTrainingState, cancelTraining, listTrainings } =
		TrainingStore.useContainer();

	useEffect(() => {
		setReason("");
	}, [open]);

	// providing feedback about cancelling process
	useEffect(() => {
		if (cancelTrainingState.state === State.DONE) {
			enqueueSnackbar(formatMessage(messages.cancelledSuccesfully), {
				variant: "success",
			});
			listTrainings();
			cancelTrainingState.reset();
		} else if (cancelTrainingState.state === State.ERROR) {
			enqueueSnackbar(formatMessage(messages.errorMessage), {
				variant: "error",
			});
			cancelTrainingState.reset();
		}
	}, [
		trainingId,
		open,
		cancelTrainingState,
		enqueueSnackbar,
		formatMessage,
		listTrainings,
	]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				<FormattedMessage {...messages.title} />
			</DialogTitle>

			<DialogContent>
				<DialogContentText>
					<FormattedMessage {...messages.content} />
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label={formatMessage(messages.reason)}
					type="outlined"
					fullWidth
					onChange={(e) => {
						setReason(e.target.value);
					}}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose} color="primary">
					<FormattedMessage {...messages.cancel} />
				</Button>
				<Button
					onClick={() => {
						if (reason !== "") {
							cancelTraining({ trainingId, reason });
							handleClose();
							setReason("");
						} else {
							enqueueSnackbar(
								formatMessage(messages.missingReason),
								{
									variant: "error",
								}
							);
						}
					}}
					color="primary"
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};
