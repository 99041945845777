import React, { FC, useEffect } from "react";

import { LoadingSnackBar, PersonList } from "../components";
import { PersonStore, State, UserStore } from "../stores";

export const PersonView: FC = () => {
	const { currentUser } = UserStore.useContainer();
	const { listPersons, personList, listPersonsState } =
		PersonStore.useContainer();

	useEffect(() => {
		listPersons();
	}, [listPersons]);

	return listPersonsState.state === State.DONE &&
		typeof currentUser != "undefined" ? (
		<PersonList persons={personList} currentUser={currentUser} />
	) : (
		<LoadingSnackBar />
	);
};
