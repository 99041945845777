import { PersonDto } from ".";

export type ParticipationInfoDto = {
	id: number;
	person: PersonDto;
	trainingId: number;
	isThere: number;
	replacement: boolean;
};

export type Attendance = {
	participationInfoId: number;
	presenceType: number;
};

export enum PresenceEnum {
	Initial = 0,
	Present = 1,
	Absent = 2,
	Forcasted = 3,
}
