import axios from "axios";

import { API_URL, postRequestConfig } from "./config";
import { TrainingDto, Training, CancelTrainingDto } from "../types";

interface TrainingApi {
	getTraining(trainingId: number): Promise<Training>;
	listAllTrainings(): Promise<TrainingDto[]>;
	cancelTraining(training: CancelTrainingDto): Promise<void>;
	getCancellationReason(trainingId: number): Promise<string>;
}

export const trainingApi: TrainingApi = {
	async getTraining(trainingId: number) {
		const response = await axios(`${API_URL}/trainings/${trainingId}`);
		return response.data;
	},

	async listAllTrainings() {
		const response = await axios(`${API_URL}/trainings`);
		return response.data;
	},

	async cancelTraining(training: CancelTrainingDto) {
		await axios.post(
			`${API_URL}/trainings/${training.trainingId}/cancel`,
			{ Reason: training.reason },
			postRequestConfig
		);
	},

	async getCancellationReason(trainingId: number) {
		const response = await axios(
			`${API_URL}/trainings/cancelled/${trainingId}`
		);
		const { reason } = response.data;
		return reason;
	},
};
