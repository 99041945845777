import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { accountApi } from "../api";
import { LoginDto, UserDto } from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface UserStoreResult {
	loginUserState: RequestResult;
	logoutUserState: RequestResult;
	introspectionState: RequestResult;

	currentUser: UserDto | undefined;

	loginUser(user: LoginDto): Promise<void>;
	logoutUser(): Promise<void>;
	introspectUser(): Promise<void>;
}

const useUser = (): UserStoreResult => {
	const loginUserState = useRequestState();
	const logoutUserState = useRequestState();
	const introspectionState = useRequestState();

	const [currentUser, setCurrentUser] = useState<UserDto | undefined>(
		undefined
	);

	const loginUser = useCallback(
		async (user: LoginDto) => {
			try {
				loginUserState.pending();

				await accountApi.loginUser(user);

				loginUserState.done();
			} catch (error: any) {
				loginUserState.error(error?.response?.status ?? 0);
			}
		},
		[loginUserState]
	);

	const logoutUser = useCallback(async () => {
		try {
			logoutUserState.pending();

			await accountApi.logoutUser();

			logoutUserState.done();
		} catch (error: any) {
			logoutUserState.error(error?.response.status ?? 0);
		}
	}, [logoutUserState]);

	const introspectUser = useCallback(async () => {
		try {
			introspectionState.pending();

			const introspectionResult = await accountApi.introspectUser();
			setCurrentUser(introspectionResult);

			introspectionState.done();
		} catch (error: any) {
			introspectionState.error(error?.response.status ?? 0);
		}
	}, [introspectionState]);

	return {
		loginUserState: toRequestResult(loginUserState),
		logoutUserState: toRequestResult(logoutUserState),
		introspectionState: toRequestResult(introspectionState),
		currentUser,
		loginUser,
		logoutUser,
		introspectUser,
	};
};

export const UserStore = createContainer(useUser);
