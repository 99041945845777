import React, { FC } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";

const AntSwitch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 31,
			height: 16,
			padding: 2,
			display: "flex",
		},
		switchBase: {
			padding: 2.5,
			color: theme.palette.grey[500],
			"&$checked": {
				transform: "translateX(12px)",
				color: theme.palette.common.white,
				"& + $track": {
					opacity: 0.8,
					backgroundColor: theme.palette.primary.main,
					borderColor: theme.palette.primary.main,
				},
			},
		},
		thumb: {
			width: 13,
			height: 13,
			boxShadow: "none",
		},
		track: {
			border: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: 9,
			opacity: 1,
			backgroundColor: theme.palette.common.white,
		},
		checked: {},
	})
)(Switch);

type Props = {
	checked: boolean;
	firstOption: string;
	secondOption: string;
	action: (checkedState: boolean) => void;
};

export const SmallSwitch: FC<Props> = ({
	checked,
	firstOption,
	secondOption,
	action,
}) => {
	const [checkedState, setCheckedState] = React.useState(checked);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedState(event.target.checked);
		action(checkedState);
	};

	return (
		<Grid component="label" container alignItems="center" spacing={1}>
			<Grid item>{firstOption}</Grid>
			<Grid item>
				<AntSwitch
					checked={checkedState}
					onChange={handleChange}
					name="smallSwitch"
				/>
			</Grid>
			<Grid item>{secondOption}</Grid>
		</Grid>
	);
};
