import { Toolbar } from "@material-ui/core";
import React, { FC } from "react";

import { listStyles } from "./dataDisplay/listStyle";

type Props = {
	title: string;
	children?: any; // for further customization
};

export const LightToolbar: FC<Props> = ({ title, children }) => {
	const classes = listStyles();
	return (
		<Toolbar variant="dense" className={classes.lightPersonListToolbar}>
			{title}
			{children}
		</Toolbar>
	);
};
