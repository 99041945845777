import React, { FC } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import "./formStyle.css";
import { DateRange } from "../../types";

type Props = {
	value: string;
	label: string;
	format: string;
	onChange: (
		date: MaterialUiPickersDate,
		value?: string | null | undefined
	) => void;
	onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	errorCheck: boolean;
	name: string;
	disableRange: DateRange;
};

export const DatePicker: FC<Props> = ({
	value,
	label,
	format,
	onChange,
	onBlur,
	errorCheck,
	name,
	disableRange: dateRange,
}) => {
	return (
		<KeyboardDatePicker
			disableFuture={dateRange == DateRange.Future}
			disablePast={dateRange == DateRange.Past}
			invalidDateMessage=""
			id="date-picker-dialog"
			label={label}
			name={name}
			format={format}
			value={value === "" ? null : value}
			onChange={onChange}
			onBlur={onBlur}
			error={errorCheck}
			variant="dialog"
			inputVariant="outlined"
			KeyboardButtonProps={{
				"aria-label": "change date",
			}}
			className="wide"
		/>
	);
};
