import React, { FC, useCallback, useEffect } from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";

import { MenuBar } from ".";
import {
	Registration,
	CoursesList,
	PersonInput,
	CourseCreation,
	CourseDetails,
	Login,
	PersonView,
	PersonEdit,
	PersonAbsences,
	PaymentView,
	AttendancePage,
	AdminMainPage,
	SettingsPage,
	CalendarMode,
} from "../../pages";
import { CourseTypeCreation } from "../../pages/CourseTypeCreation";
import { State, UserStore } from "../../stores";
import { Role } from "../../types";
import { EnrollmentsHandler } from "../../pages/EnrollmentsHandler";
import { LoadingSnackBar } from "..";

export const MenuOptions: FC = () => {
	const { introspectUser, currentUser, introspectionState } =
		UserStore.useContainer();
	const checkLogin = useCallback(
		() => currentUser?.loginStatus || false,
		[currentUser]
	);

	const authorize = useCallback(
		(requiredRole: Role) =>
			checkLogin() && currentUser?.role === requiredRole,
		[checkLogin, currentUser?.role]
	);

	const freeRoutes = [
		<Route
			key="registration"
			path="/registration"
			component={Registration}
		/>,
		<Route key="login" path="/login" component={Login} />,
	];

	const simpleRoutes = [
		<Route
			key="courseList"
			exact
			path="/courses"
			component={CoursesList}
		/>,
		<Route
			key="courseDetails"
			path="/courses/:id(\d+)"
			component={CourseDetails}
		/>,
		<Route key="personList" exact path="/persons" component={PersonView} />,
	];

	const adminUserRoutes = [
		<Route key="home" exact path="/" component={AdminMainPage} />,
		<Route
			key="createCourse"
			path="/courses/create"
			component={CourseCreation}
		/>,
		<Route
			key="courseType"
			path="/courseTypes/create"
			component={CourseTypeCreation}
		/>,
		<Route
			key="enroll"
			path="/enrollments/maintain"
			component={EnrollmentsHandler}
		/>,
		<Route
			key="payment"
			path="/persons/:id(\d+)/payments"
			component={PaymentView}
		/>,

		<Route
			key="attendance"
			path="/trainings/:id(\d+)/attendance"
			component={AttendancePage}
		/>,

		<Route key="settings" path="/settings" component={SettingsPage} />,
	];

	const normalUserRoutes = [
		<Route key="home" exact path="/" component={CalendarMode} />,
		<Route
			key="addPerson"
			exact
			path="/persons/add"
			component={PersonInput}
		/>,
		<Route
			key="editPerson"
			path="/persons/:id(\d+)/edit"
			component={PersonEdit}
		/>,
		<Route
			key="absentPerson"
			path="/persons/:id(\d+)/absences"
			component={PersonAbsences}
		/>,
	];

	useEffect(() => {
		introspectUser();
	}, [introspectUser]);

	return introspectionState.state === State.DONE ? (
		<Router>
			<MenuBar currentUser={currentUser} isLoggedIn={checkLogin()} />
			<Switch>
				{freeRoutes}

				{checkLogin() && simpleRoutes}

				{authorize(Role.ADMIN) ? (
					adminUserRoutes
				) : authorize(Role.NORMAL) ? (
					normalUserRoutes
				) : (
					<Redirect to="/login" />
				)}

				<Redirect to="/" />
			</Switch>
		</Router>
	) : (
		<LoadingSnackBar />
	);
};
