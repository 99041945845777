import React, { FC } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import * as yup from "yup";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { shouldShowErrorMessage, withoutTime } from "../utils";
import { DateRange, Person, PersonEditDto } from "../../types";
import { RequestResult } from "../../stores/types";
import { DatePicker } from "./DatePicker";
import { SubmitProgress } from ".";
import { frequentMessages } from "../../locales";
import "./formStyle.css";
import { useStyles } from "../../assets";

const messages = defineMessages({
	nameInput: {
		id: "personEditForm.name",
		defaultMessage: "Name",
	},
	submit: {
		id: "personEditForm.submit",
		defaultMessage: "Edit",
	},
	requiredName: {
		id: "personEditForm.requiredName",
		defaultMessage: "Name required!",
	},
	requiredDate: {
		id: "personEditForm.requireDate",
		defaultMessage: "Date required!",
	},
});

type Props = {
	onSubmit: (personEditDto: PersonEditDto) => void;
	person?: Person;
	requestResult: RequestResult;
};

export const PersonEditForm: FC<Props> = ({
	onSubmit,
	person,
	requestResult,
}) => {
	const { formatMessage, formatDate } = useIntl();
	const classes = useStyles();

	const validationSchema = yup.object().shape({
		name: yup.string().required(formatMessage(messages.requiredName)),
		birthDate: yup.date().required(formatMessage(messages.requiredDate)),
	});

	const initialValues: PersonEditDto = {
		name: person?.name ?? "",
		birthDate: person?.birthDate ?? "",
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				values.birthDate = withoutTime(values.birthDate);
				values.birthDate = formatDate(values.birthDate);
				onSubmit(values);
			}}
		>
			{(formikProps) => {
				return (
					<div className="formDiv">
						<Paper variant="elevation" className="paper">
							<Form className="inputContainer">
								<EditIcon className={classes.mainLogo} />
								<Typography variant="h4">
									<FormattedMessage
										{...frequentMessages.editPerson}
									/>
								</Typography>

								<div className="inputElement">
									<Field
										as={TextField}
										label={formatMessage(
											messages.nameInput
										)}
										id="name"
										name="name"
										type="text"
										color="primary"
										variant="outlined"
										onChange={formikProps.handleChange}
										error={shouldShowErrorMessage(
											formikProps,
											"name"
										)}
										className="wide"
									/>
									<ErrorMessage name="name">
										{(msg) => (
											<div className="errorMessage">
												{msg}
											</div>
										)}
									</ErrorMessage>
								</div>
								<div className="inputElement">
									<DatePicker
										disableRange={DateRange.Future}
										label={formatMessage(
											frequentMessages.birthDate
										)}
										name="birthDate"
										value={formikProps.values.birthDate}
										format={formatDate(
											formikProps.values.birthDate
										)}
										onChange={(value) =>
											formikProps.setFieldValue(
												"birthDate",
												value ? value : ""
											)
										}
										onBlur={formikProps.handleBlur}
										errorCheck={shouldShowErrorMessage(
											formikProps,
											"birthDate"
										)}
									/>
									<ErrorMessage name="birthDate">
										{(msg) => (
											<div className="errorMessage">
												{msg}
											</div>
										)}
									</ErrorMessage>
								</div>

								<Button
									type="submit"
									variant="contained"
									value="Submit"
									color="primary"
									disabled={!formikProps.isValid}
								>
									<FormattedMessage {...messages.submit} />
								</Button>

								<SubmitProgress requestResult={requestResult} />
							</Form>
						</Paper>
					</div>
				);
			}}
		</Formik>
	);
};
