import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import React, { FC, useEffect } from "react";

import { CalendarView } from "../components";
import { TrainingStore, LanguageStore } from "../stores";
import { TrainingDto } from "../types";

import "./pages.css";

export const AdminMainPage: FC = () => {
	const { listTrainings, trainingList } = TrainingStore.useContainer();
	const languageStore = LanguageStore.useContainer();

	useEffect(() => {
		listTrainings();
	}, [listTrainings]);

	let trainings: AppointmentModel[] = trainingList.map(
		(training: TrainingDto) => {
			let startHour = training.course.startHour.split(":");
			let endHour = training.course.endHour.split(":");
			let newDate = training.date.split("T");
			let parts = newDate[0].split("-");
			return {
				title: training.course.type,
				startDate: new Date(
					parseInt(parts[0]),
					parseInt(parts[1]) - 1,
					parseInt(parts[2]),
					parseInt(startHour[0]),
					parseInt(startHour[1])
				),
				endDate: new Date(
					parseInt(parts[0]),
					parseInt(parts[1]) - 1,
					parseInt(parts[2]),
					parseInt(endHour[0]),
					parseInt(endHour[1])
				),
				id: training.id,
				isCancelled: training.isCancelled,
			};
		}
	);

	return (
		<div className="mainDiv">
			<CalendarView
				appointmentModel={trainings}
				showDays={true}
				locale={languageStore.locale}
				urlPrefix="/trainings"
			/>
		</div>
	);
};
