import axios from "axios";

import { LoginDto, UserDto } from "../types";
import { USER_URL, postRequestConfig } from "./config";

interface AccountApi {
	loginUser(user: LoginDto): Promise<void>;
	logoutUser(): Promise<void>;
	introspectUser(): Promise<UserDto>;
}

export const accountApi: AccountApi = {
	async loginUser(user: LoginDto) {
		await axios.post(`${USER_URL}/login`, user, postRequestConfig);
	},
	async logoutUser() {
		await axios.post(`${USER_URL}/logout`, postRequestConfig);
	},
	async introspectUser() {
		const response = await axios(`${USER_URL}/introspect`);
		return response.data;
	},
};
