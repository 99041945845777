import {
	Button,
	MenuItem,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { FC } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { RequestResult } from "../../stores";
import {
	EnrollmentCreationDto,
	PersonDto,
	WaitingListElementDto,
} from "../../types";
import { SubmitProgress } from "./SubmitProgress";
import { getNotEnrolledPersons } from "../utils";
import "./formStyle.css";
import { frequentMessages } from "../../locales";

type Props = {
	onSubmit: (enrollment: EnrollmentCreationDto) => void;
	requestResult: RequestResult;
	persons: PersonDto[];
	enrolledPersons: PersonDto[];
	personsOnWaitingList: WaitingListElementDto[];
	submitButtonText: string;
	fullCourse: string;
	initialValues: WaitingListElementDto | EnrollmentCreationDto;
};

const messages = defineMessages({
	title: {
		id: "enrollment.title",
		defaultMessage: "Enrollment",
	},

	person: {
		id: "enrollment.person",
		defaultMessage: "Person",
	},
});

export const EnrollmentForm: FC<Props> = ({
	onSubmit,
	requestResult,
	persons,
	enrolledPersons,
	personsOnWaitingList: waitingPersonsList,
	submitButtonText,
	fullCourse,
	initialValues,
}) => {
	const { formatMessage } = useIntl();
	const { id } = useParams<{ id: string }>();
	let notEnrolledPersons: PersonDto[] = getNotEnrolledPersons(
		persons,
		enrolledPersons,
		waitingPersonsList
	);

	return (
		<div className="flexFormDiv">
			<Formik
				initialValues={initialValues}
				onSubmit={(values, action) => {
					values.courseId = Number(id);
					onSubmit(values);
					action.resetForm({
						values: initialValues,
					});
				}}
			>
				{(formikProps) => (
					<Paper variant="elevation" className="enrollPaper">
						<Form className="inputContainer">
							<Typography variant="h4">
								<FormattedMessage {...messages.title} />
							</Typography>
							{fullCourse}
							<div className="inputElement">
								<Field
									as={TextField}
									label={formatMessage(messages.person)}
									select
									id="personId"
									type="text"
									name="personId"
									color="primary"
									variant="outlined"
									onChange={formikProps.handleChange}
									value={
										formikProps.values.personId === -1
											? ""
											: formikProps.values.personId
									}
									className="wide"
								>
									{notEnrolledPersons.map((person) => (
										<MenuItem
											key={person.id}
											value={person.id}
										>
											{person.name}
										</MenuItem>
									))}
								</Field>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									label={formatMessage(
										frequentMessages.notes
									)}
									multiline
									rows={4}
									variant="outlined"
									className="wide"
									onChange={formikProps.handleChange}
									name="notes"
								/>
							</div>

							<Button
								type="submit"
								variant="contained"
								value="Submit"
								color="primary"
								disabled={!formikProps.isValid}
							>
								{submitButtonText}
							</Button>

							<SubmitProgress requestResult={requestResult} />
						</Form>
					</Paper>
				)}
			</Formik>
		</div>
	);
};
