import React, { FC, useEffect } from "react";

import { CourseStore, SettingStore } from "../stores";
import { CourseList } from "../components";
import { CalendarMode } from ".";
import "../App.css";

export const CoursesList: FC = () => {
	const listStore = CourseStore.useContainer();
	const { listCourses } = CourseStore.useContainer();
	const { courseListDisplayMode } = SettingStore.useContainer();

	useEffect(() => {
		listCourses();
	}, [listCourses, courseListDisplayMode]);

	return (
		<div>
			{courseListDisplayMode === "calendar" ? (
				<CalendarMode courseList={listStore.courseList} />
			) : (
				<CourseList courses={listStore.courseList} />
			)}
		</div>
	);
};
