import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { courseApi } from "../api";
import { Course, CourseCreationDto, CourseReducedDto } from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface CourseStoreResult {
	courseCreationState: RequestResult;
	listCoursesState: RequestResult;
	getDetailsState: RequestResult;
	courseList: CourseReducedDto[];
	currentCourse?: Course;
	createCourse(course: CourseCreationDto): Promise<void>;
	listCourses(): Promise<void>;
	getDetails(id: number): Promise<void>;
}

const useCourseStore = (): CourseStoreResult => {
	const courseCreationState = useRequestState();
	const listCoursesState = useRequestState();
	const getDetailsState = useRequestState();
	const [courseList, setCourseList] = useState<CourseReducedDto[]>([]);
	const [currentCourse, setCurrentCourse] = useState<Course | undefined>(
		undefined
	);
	const createCourse = useCallback(
		async (course: CourseCreationDto) => {
			try {
				courseCreationState.pending();

				await courseApi.postNewCourse(course);

				courseCreationState.done();
			} catch (error: any) {
				courseCreationState.error(error?.response?.status ?? 0);
			}
		},
		[courseCreationState]
	);

	const getDetails = useCallback(
		async (id: number) => {
			try {
				getDetailsState.pending();

				let result = await courseApi.listDetailedInformation(id);
				setCurrentCourse(result);

				getDetailsState.done();
			} catch (error: any) {
				getDetailsState.error(error?.response?.status ?? 0);
			}
		},
		[getDetailsState]
	);

	const listCourses = useCallback(async () => {
		try {
			listCoursesState.pending();

			const result = await courseApi.listAllCourses();
			setCourseList(result);

			listCoursesState.done();
		} catch (error: any) {
			listCoursesState.error(error?.response?.status ?? 0);
		}
	}, [listCoursesState]);

	return {
		courseCreationState: toRequestResult(courseCreationState),
		listCoursesState: toRequestResult(listCoursesState),
		getDetailsState: toRequestResult(getDetailsState),
		courseList,
		currentCourse,
		createCourse,
		listCourses,
		getDetails,
	};
};

export const CourseStore = createContainer(useCourseStore);
