import React, { FC, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { EventBusy } from "@material-ui/icons";
import { CircularProgress, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { State, PersonStore, AbsenceStore } from "../stores";
import { useStyles } from "../assets";
import {
	LoadingSnackBar,
	PersonAbsentForm,
	PersonRecuperationForm,
	withTime,
} from "../components";
import { AbsenceList } from "../components/dataDisplay/AbsenceList";
import { Absence, AbsenceRecuperationCreationDto } from "../types";

const messages = defineMessages({
	absenceAdded: {
		id: "absenceAddForm.absenceAdded",
		defaultMessage: "Absence added succesfully",
	},
	recuperationAdded: {
		id: "recuperationAddForm.recuperationAdded",
		defaultMessage: "Recuperation added succesfully",
	},
	absenceAddedError: {
		id: "absenceAddForm.absenceAddedError",
		defaultMessage: "Failed to add absence",
	},
	recuperationAddedError: {
		id: "recuperationAddForm.recuperationAddedError",
		defaultMessage: "Failed to add recuperation",
	},
	recuperationListError: {
		id: "recuperationAddForm.recuperationListError",
		defaultMessage: "Failed to load recuperations",
	},
	absenceNewTitle: {
		id: "absencePage.absenceNewTitle",
		defaultMessage: "New Absence",
	},
	absenceAddedTitle: {
		id: "absencePage.absenceAddedTitle",
		defaultMessage: "Added Absences",
	},
	absenceDetailsTitle: {
		id: "absencePage.absenceDetailsTitle",
		defaultMessage: "Absence Details",
	},
	absenceDetailsHint: {
		id: "absencePage.absenceDetailsHint",
		defaultMessage: "Select an existing absence or add a new one",
	},
	recuperation: {
		id: "absencePage.recuperation",
		defaultMessage: "Recuperation",
	},
});

export const PersonAbsences: FC = () => {
	const {
		currentPerson,
		getPersonDetails,
		getPersonDetailsState,
		listTrainingsForPersonState,
		listTrainingsForPerson,
		trainingListForPerson,
		listAbsencesForPersonState,
		listAbsencesForPerson,
		absenceListForPerson,
	} = PersonStore.useContainer();
	const {
		addAbsenceState,
		addAbsence,
		selectedAbsenceForRecuperation,
		selectAbsenceForRecuperation,
		getRecuperationTrainingsForAbsenceState,
		recuperationTrainingsForAbsence,
		getRecuperationTrainingsForAbsence,
		setAbsenceRecuperationState,
		setAbsenceRecuperation,
	} = AbsenceStore.useContainer();
	const { id } = useParams<{ id: string }>();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage, formatDate, formatTime } = useIntl();
	const [absenceForRecuperation, setAbsenceForRecuperation] = useState<
		Absence | undefined
	>(undefined);

	useEffect(() => {
		setAbsenceForRecuperation(undefined);
	}, []);

	useEffect(() => {
		const personId = Number(id);
		getPersonDetails(personId);
		listTrainingsForPerson(personId);
		listAbsencesForPerson(personId);
	}, [getPersonDetails, listTrainingsForPerson, listAbsencesForPerson, id]);

	useEffect(() => {
		if (addAbsenceState.state === State.DONE) {
			const personId = Number(id);
			listTrainingsForPerson(personId);
			listAbsencesForPerson(personId);
		} else if (addAbsenceState.state === State.ERROR) {
			enqueueSnackbar(formatMessage(messages.absenceAddedError), {
				variant: "error",
			});
			addAbsenceState.reset();
		}
	}, [
		id,
		addAbsenceState,
		addAbsenceState.state,
		listTrainingsForPerson,
		listAbsencesForPerson,
		enqueueSnackbar,
		formatMessage,
	]);

	useEffect(() => {
		if (getRecuperationTrainingsForAbsenceState.state === State.ERROR) {
			enqueueSnackbar(formatMessage(messages.recuperationListError), {
				variant: "error",
			});
			getRecuperationTrainingsForAbsenceState.reset();
		}
	}, [
		getRecuperationTrainingsForAbsenceState,
		getRecuperationTrainingsForAbsenceState.state,
		enqueueSnackbar,
		formatMessage,
	]);

	const onAbsenceSelected = (absence: Absence) => {
		selectAbsenceForRecuperation(absence);
		getRecuperationTrainingsForAbsence(absence.id);
		setAbsenceForRecuperation(absence);
	};

	const onRecuperationSubmit = async (
		id: number,
		recuperation: AbsenceRecuperationCreationDto
	) => {
		if (selectedAbsenceForRecuperation == null) return;
		await setAbsenceRecuperation(id, recuperation);
		const training = recuperationTrainingsForAbsence.find(
			(t) => t.id == recuperation.recuperationTrainingId
		);
		if (training != null) {
			selectedAbsenceForRecuperation.recuperationTraining = training;
		}
		selectAbsenceForRecuperation(selectedAbsenceForRecuperation);
		getRecuperationTrainingsForAbsence(selectedAbsenceForRecuperation.id);
	};

	if (
		getPersonDetailsState.state === State.DONE &&
		listTrainingsForPersonState.state === State.DONE &&
		listAbsencesForPersonState.state === State.DONE &&
		currentPerson &&
		trainingListForPerson
	) {
		return (
			<div className="mainDiv">
				<div className="iconDiv">
					<EventBusy className={classes.mainLogo} />
					<Typography variant="h4">{currentPerson.name}</Typography>
				</div>
				<div className="absenceDiv">
					<div className="innerAbsenceDiv">
						<div>
							<Typography variant="h5">
								<FormattedMessage
									{...messages.absenceNewTitle}
								/>
							</Typography>
							<PersonAbsentForm
								onSubmit={addAbsence}
								person={currentPerson}
								trainings={trainingListForPerson}
								requestResult={addAbsenceState}
							/>
						</div>
						<div>
							<Typography variant="h5">
								<FormattedMessage
									{...messages.absenceAddedTitle}
								/>
							</Typography>
							<AbsenceList
								absences={absenceListForPerson}
								onSelected={onAbsenceSelected}
							></AbsenceList>
						</div>
					</div>
					<div className="innerAbsenceDiv">
						<div>
							<Typography variant="h5">
								<FormattedMessage
									{...messages.absenceDetailsTitle}
								/>
							</Typography>
							{
								// is this the first display after selecting from person list?
								typeof absenceForRecuperation ===
								"undefined" ? (
									<Typography variant="body1">
										<FormattedMessage
											{...messages.absenceDetailsHint}
										/>
									</Typography>
								) : // display the recuperations for the selected absence with the form
								getRecuperationTrainingsForAbsenceState.state ===
								  State.DONE ? (
									<div>
										<Typography variant="body1">
											{
												absenceForRecuperation
													.absenceTraining.course.type
											}
										</Typography>
										<Typography variant="body1">
											{formatDate(
												absenceForRecuperation
													.absenceTraining.date
											)}{" "}
											{formatTime(
												withTime(
													absenceForRecuperation
														.absenceTraining.date,
													absenceForRecuperation
														.absenceTraining.course
														.startHour
												)
											)}
										</Typography>
										{absenceForRecuperation.recuperationTraining && (
											<Typography variant="body1">
												{formatMessage(
													messages.recuperation
												)}
												{": "}
												{formatDate(
													absenceForRecuperation
														.recuperationTraining
														.date
												)}{" "}
												{formatTime(
													withTime(
														absenceForRecuperation
															.recuperationTraining
															.date,
														absenceForRecuperation
															.recuperationTraining
															.course.startHour
													)
												)}
											</Typography>
										)}
										<PersonRecuperationForm
											absence={absenceForRecuperation}
											availableTrainings={recuperationTrainingsForAbsence.filter(
												(training) =>
													!training.isCancelled
											)}
											requestResult={
												setAbsenceRecuperationState
											}
											onSubmit={onRecuperationSubmit}
										/>
									</div>
								) : (
									<CircularProgress />
								)
							}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return <LoadingSnackBar />;
	}
};
