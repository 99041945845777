import { useSnackbar } from "notistack";
import React, { FC } from "react";
import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { CourseCreationForm, LoadingSnackBar } from "../components";
import { CourseStore, CourseTypeStore, State } from "../stores";

const messages = defineMessages({
	errorMessage: {
		id: "courseCreation.error",
		defaultMessage: "Creation was unsuccessful! Please try again!",
	},
});

export const CourseCreation: FC = () => {
	const { createCourse, courseCreationState } = CourseStore.useContainer();
	const { getCourseTypes, courseTypes, getCourseTypesState } =
		CourseTypeStore.useContainer();

	useEffect(() => {
		getCourseTypes();
	}, [getCourseTypes]);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (courseCreationState.state === State.DONE) {
			history.push("/");
			courseCreationState.reset();
		} else if (courseCreationState.state === State.ERROR) {
			enqueueSnackbar(formatMessage(messages.errorMessage), {
				variant: "error",
			});
			courseCreationState.reset();
		}
	}, [
		courseCreationState,
		courseCreationState.state,
		enqueueSnackbar,
		formatMessage,
		history,
	]);

	return getCourseTypesState.state === State.DONE ? (
		<CourseCreationForm
			onSubmit={createCourse}
			requestResult={courseCreationState}
			types={courseTypes}
		/>
	) : (
		<LoadingSnackBar />
	);
};
