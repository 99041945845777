/* eslint-disable react/jsx-max-depth */
import React, { FC } from "react";
import { SnackbarProvider } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core";

import {
	CourseStore,
	CourseTypeStore,
	LanguageStore,
	UserStore,
	PersonStore,
	RegistrationStore,
	EnrollmentStore,
	AbsenceStore,
	PaymentStore,
	AttendanceStore,
	TrainingStore,
	WaitingListStore,
	SettingStore,
} from "./stores";
import { IntlLanguageProvider, MenuOptions } from "./components";
import { theme } from "./assets";
import "./App.css";

export const App: FC = () => {
	return (
		<LanguageStore.Provider>
			<IntlLanguageProvider>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<RegistrationStore.Provider>
						<UserStore.Provider>
							<CourseStore.Provider>
								<CourseTypeStore.Provider>
									<PersonStore.Provider>
										<EnrollmentStore.Provider>
											<TrainingStore.Provider>
												<AbsenceStore.Provider>
													<PaymentStore.Provider>
														<AttendanceStore.Provider>
															<WaitingListStore.Provider>
																<SettingStore.Provider>
																	<MuiThemeProvider
																		theme={
																			theme
																		}
																	>
																		<SnackbarProvider
																			maxSnack={
																				3
																			}
																		>
																			<MenuOptions />
																		</SnackbarProvider>
																	</MuiThemeProvider>
																</SettingStore.Provider>
															</WaitingListStore.Provider>
														</AttendanceStore.Provider>
													</PaymentStore.Provider>
												</AbsenceStore.Provider>
											</TrainingStore.Provider>
										</EnrollmentStore.Provider>
									</PersonStore.Provider>
								</CourseTypeStore.Provider>
							</CourseStore.Provider>
						</UserStore.Provider>
					</RegistrationStore.Provider>
				</MuiPickersUtilsProvider>
			</IntlLanguageProvider>
		</LanguageStore.Provider>
	);
};
