import React, { FC, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSnackbar } from "notistack";

import { PersonForm } from "../components";
import { PersonStore, State } from "../stores";

const messages = defineMessages({
	personCreated: {
		id: "personInput.personCreated",
		defaultMessage: "Person added sucesfully",
	},
	personCreationError: {
		id: "personInput.personCreationError",
		defaultMessage: "Failed to add person",
	},
});

export const PersonInput: FC = () => {
	const { addPerson, addPersonState, listPersons } =
		PersonStore.useContainer();
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (addPersonState.state === State.DONE) {
			enqueueSnackbar(formatMessage(messages.personCreated), {
				variant: "success",
			});
			listPersons();
		} else if (addPersonState.state === State.ERROR) {
			enqueueSnackbar(formatMessage(messages.personCreationError), {
				variant: "error",
			});
		}
	}, [
		addPersonState,
		addPersonState.state,
		enqueueSnackbar,
		formatMessage,
		listPersons,
	]);

	return <PersonForm onSubmit={addPerson} requestResult={addPersonState} />;
};
