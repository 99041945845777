import axios from "axios";

import { PaymentDto, PaymentSetterDto } from "../types/payment";
import { API_URL, postRequestConfig } from "./config";

interface PaymentApi {
	getPaymentsByPerson(personId: number): Promise<PaymentDto[]>;
	signPaidMonths(dtoForPayments: PaymentSetterDto): Promise<void>;
}

export const paymentApi: PaymentApi = {
	async getPaymentsByPerson(personId: number) {
		const response = await axios(`${API_URL}/payments`, {
			params: { personId },
		});
		return response.data;
	},

	async signPaidMonths(dtoForPayments: PaymentSetterDto) {
		await axios.patch(
			`${API_URL}/payments`,
			dtoForPayments,
			postRequestConfig
		);
	},
};
