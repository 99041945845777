import React, { ChangeEvent, FC, useEffect, useState } from "react";
import PersonIcon from "@material-ui/icons/Person";
import {
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	List,
	ListItem,
	Paper,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";
import { Pagination } from "@material-ui/lab";

import {
	PaymentDto,
	PaymentSetterDto,
	PaymentSkeleton,
	CourseReducedDto,
} from "../../types";
import { listStyles } from "./listStyle";
import { getMonthList } from "..";
import { LanguageStore } from "../../stores";
import { frequentMessages } from "../../locales";
import "./dataDisplay.css";

const messages = defineMessages({
	noPayments: {
		id: "paymentInfo.noPayments",
		defaultMessage: "There are no payments for this person.",
	},
	mark: {
		id: "paymentInfo.mark",
		defaultMessage: "Mark as paid",
	},
	save: {
		id: "paymentInfo.save",
		defaultMessage: "Save modifications",
	},
	saveRequested: {
		id: "paymentInfo.saveRequested",
		defaultMessage: "Saving...",
	},
});

const days = [
	frequentMessages.monday,
	frequentMessages.tuesday,
	frequentMessages.wednesday,
	frequentMessages.thursday,
	frequentMessages.friday,
	frequentMessages.saturday,
	frequentMessages.sunday,
];

type Props = {
	payments: PaymentDto[];
	saveEditedPayments: (monthsForPayment: PaymentSetterDto) => Promise<void>;
};

export const PaymentInfo: FC<Props> = ({ payments, saveEditedPayments }) => {
	const classes = listStyles();
	const { formatMessage } = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const [page, setPage] = useState(1);
	const [paymentsToSet, setPaymentsToSet] = useState<PaymentSetterDto[]>([]);
	const { id } = useParams<{ id: string }>();
	const { locale } = LanguageStore.useContainer();
	const months = getMonthList(locale, "long");

	// checkbox onClick event
	const signPaidThisMonth = (
		e: ChangeEvent<HTMLInputElement>,
		paymentPerMonth: PaymentSkeleton,
		payment: PaymentDto
	) => {
		const isPaid = e.target.checked;
		const targetPayments = paymentsToSet.find(
			(p) => p.courseId === payment.course.id
		);

		if (typeof targetPayments === "undefined") {
			return;
		}

		// ad this month to the request
		if (isPaid) {
			targetPayments.months.push(paymentPerMonth.month);
		} else {
			// remove the month of this payment from request
			const indexOfMonth = targetPayments.months.indexOf(
				paymentPerMonth.month
			);
			if (indexOfMonth >= 0) {
				targetPayments?.months.splice(indexOfMonth, 1);
			}
		}
	};

	// send data to the server
	const savePayments = () => {
		paymentsToSet.forEach((paymentToSet: PaymentSetterDto) => {
			if (paymentToSet.months.length > 0) {
				saveEditedPayments(paymentToSet);
			}
		});
		enqueueSnackbar(formatMessage(messages.saveRequested), {
			variant: "info",
		});
	};

	const handlePageChange = (
		_e: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	const showTooltip = (course: CourseReducedDto) => {
		return `${formatMessage(days[course.day - 1])}, ${course.startHour}`;
	};

	useEffect(() => {
		// represents the request object for each course
		const initialRepresentation = initializeEditResult(
			payments,
			Number(id)
		);
		setPaymentsToSet(initialRepresentation);

		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [id, payments]);

	if (payments.length === 0) {
		return (
			<Paper variant="elevation" className="paymentPaper">
				<h1 className="title">{formatMessage(messages.noPayments)}</h1>
			</Paper>
		);
	}

	const desktopView = () => (
		<div className="paymentRowFlex">
			{payments.map((payment, index) => (
				<Card key={index} raised>
					<CardContent>
						<Tooltip
							title={showTooltip(payment.course)}
							aria-label={showTooltip(payment.course)}
							placement="top-end"
						>
							<Typography
								key={payment.course.id}
								color="textPrimary"
								variant="h6"
							>
								{payment.course.type}
								<hr />
								<List className={classes.paymentList}>
									{payment.months.map(
										(paymentPerMonth: PaymentSkeleton) => (
											<ListItem
												key={`${payment.course.id}-${paymentPerMonth.month}`}
											>
												<Typography>
													{
														months[
															paymentPerMonth.month -
																1
														]
													}
												</Typography>
												<Tooltip
													title={formatMessage(
														messages.mark
													)}
													aria-label={formatMessage(
														messages.mark
													)}
													placement="right-end"
												>
													<Checkbox
														size="medium"
														color="primary"
														disabled={
															paymentPerMonth.isPaid
														}
														defaultChecked={
															paymentPerMonth.isPaid
														}
														onChange={(
															e: ChangeEvent<HTMLInputElement>
														) =>
															signPaidThisMonth(
																e,
																paymentPerMonth,
																payment
															)
														}
													/>
												</Tooltip>
											</ListItem>
										)
									)}
								</List>
							</Typography>
						</Tooltip>
					</CardContent>
				</Card>
			))}
		</div>
	);

	const mobileView = () => (
		<div className="paymentColumnFlex">
			<Card>
				<CardContent>
					<Pagination
						count={payments.length}
						color="primary"
						onChange={handlePageChange}
					/>
					<Tooltip
						title={showTooltip(payments[page - 1].course)}
						aria-label={showTooltip(payments[page - 1].course)}
						placement="right-end"
					>
						<Typography color="textPrimary" variant="h6">
							<hr />
							{payments[page - 1].course.type}
							<hr />
						</Typography>
					</Tooltip>
					<List className={classes.paymentList}>
						{payments[page - 1].months.map(
							(paymentPerMonth: PaymentSkeleton) => (
								<ListItem
									key={`${paymentPerMonth.month}/${page}`}
								>
									<Tooltip
										title={formatMessage(messages.mark)}
										aria-label={formatMessage(
											messages.mark
										)}
										placement="right-end"
									>
										<FormControlLabel
											control={
												<Checkbox
													size="medium"
													color="primary"
													disabled={
														paymentPerMonth.isPaid
													}
													defaultChecked={
														paymentPerMonth.isPaid
													}
													onChange={(
														e: ChangeEvent<HTMLInputElement>
													) =>
														signPaidThisMonth(
															e,
															paymentPerMonth,
															payments[page - 1]
														)
													}
												/>
											}
											label={
												months[
													paymentPerMonth.month - 1
												]
											}
										/>
									</Tooltip>
								</ListItem>
							)
						)}
					</List>
				</CardContent>
			</Card>
		</div>
	);

	return (
		<Paper variant="elevation" className="paymentPaper">
			<h1 className="title">
				<PersonIcon className="icon" fontSize="medium" />
				{payments[0].person.name}
			</h1>
			{windowSize.width > 600 ? desktopView() : mobileView()}
			<Button
				variant="contained"
				color="primary"
				startIcon={<SaveIcon />}
				className={`${classes.button} ${classes.alignedMiddle}`}
				onClick={savePayments}
			>
				{formatMessage(messages.save)}
			</Button>
		</Paper>
	);
};

const initializeEditResult = (payments: PaymentDto[], personId: number) => {
	const courseIds = Array.from(
		new Set(payments.map((payment) => payment.course.id))
	);
	const paymentsToSet: PaymentSetterDto[] = courseIds.map((id) => ({
		personId: personId,
		courseId: id,
		months: [],
	}));
	return paymentsToSet;
};
