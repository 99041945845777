import React, { FC, ReactNode, useEffect, useState } from "react";
import {
	Toolbar,
	Button,
	AppBar,
	MenuItem,
	Menu,
	Drawer,
	Typography,
	IconButton,
} from "@material-ui/core";
import { AccountCircle, Flag, Menu as MenuIcon } from "@material-ui/icons";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { LanguageStore } from "../../stores";
import { useStyles } from "../../assets";
import { AccountMenu } from ".";
import { frequentMessages } from "../../locales";
import { Role, UserDto } from "../../types";

const messages = defineMessages({
	english: {
		id: "menu.English",
		defaultMessage: "English",
	},

	hungarian: {
		id: "menu.Hungarian",
		defaultMessage: "Hungarian",
	},

	account: {
		id: "menu.account",
		defaultMessage: "Account",
	},

	language: {
		id: "menu.language",
		defaultMessage: "Language",
	},

	addPerson: {
		id: "menu.addPerson",
		defaultMessage: "Add Person",
	},

	viewPerson: {
		id: "menu.viewPerson",
		defaultMessage: "View Person",
	},

	home: {
		id: "menu.home",
		defaultMessage: "Home",
	},

	createCourses: {
		id: "menu.createCourses",
		defaultMessage: "Create Courses",
	},

	viewCourses: {
		id: "menu.viewCourses",
		defaultMessage: "View Courses",
	},

	menu: {
		id: "menu.menu",
		defaultMessage: "Menu",
	},

	signInMessage: {
		id: "menu.signInMessage",
		defaultMessage: "Please log in first!",
	},

	createCourseType: {
		id: "menu.createCourseType",
		defaultMessage: "Create Course Type",
	},

	enrollments: {
		id: "menu.enrollments",
		defaultMessage: "Enrollments",
	},
});

type Props = {
	currentUser: UserDto | undefined;
	isLoggedIn: boolean;
};

export const MenuBar: FC<Props> = ({ currentUser, isLoggedIn }) => {
	//--------------STATES & EFFECTS---------
	const classes = useStyles();
	const [mobileView, setMobileView] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);

	useEffect(() => {
		const setResponsiveness = () => {
			return window.innerWidth < 900
				? setMobileView(true)
				: setMobileView(false);
		};

		setResponsiveness();

		window.addEventListener("resize", setResponsiveness);

		return () => {
			window.removeEventListener("resize", setResponsiveness);
		};
	}, []);

	const languageStore = LanguageStore.useContainer();
	const { formatMessage } = useIntl();
	const [openPersons, setOpenPersons] = useState<HTMLButtonElement | null>(
		null
	);
	const [openAccount, setOpenAccount] = useState<HTMLButtonElement | null>(
		null
	);
	const [openLanguages, setOpenLanguages] =
		useState<HTMLButtonElement | null>(null);
	const [openCourses, setOpenCourses] = useState<HTMLButtonElement | null>(
		null
	);

	const handleClickPersons = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenPersons(event.currentTarget);
	};

	const handleClickAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenAccount(event.currentTarget);
	};

	const handleClickLanguages = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setOpenLanguages(event.currentTarget);
	};

	const handleSelectLang = (event: React.MouseEvent<HTMLLIElement>) => {
		if (event.currentTarget.value === 1) {
			languageStore.selectLanguage("hu");
		} else {
			languageStore.selectLanguage("en");
		}
		setOpenLanguages(null);
	};

	const handleClickCourses = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpenCourses(event.currentTarget);
	};

	const handleClosePersons = () => {
		setOpenPersons(null);
	};

	const handleCloseAccount = () => {
		setOpenAccount(null);
	};

	const handleCloseLanguages = () => {
		setOpenLanguages(null);
	};

	const handleCloseCourses = () => {
		setOpenCourses(null);
	};

	//-----------NAVIGATION TOOLS------------
	const homeButton = (
		<Button
			key="homeButton"
			color="inherit"
			className={classes.buttons}
			component={Link}
			to="/"
		>
			<FormattedMessage {...messages.home} />
		</Button>
	);

	const settingsButton = (
		<Button
			key="settingsButton"
			color="inherit"
			className={classes.buttons}
			component={Link}
			to="/settings"
		>
			<FormattedMessage {...frequentMessages.settings} />
		</Button>
	);

	const coursesButton = (
		<Button
			key="coursesButton"
			color="inherit"
			onClick={handleClickCourses}
			className={classes.buttons}
		>
			<FormattedMessage {...frequentMessages.courses} />
		</Button>
	);

	const courseViewMenuItem = (
		<MenuItem
			key="courseViewMenuItem"
			onClick={handleCloseCourses}
			component={Link}
			to="/courses"
		>
			{formatMessage(messages.viewCourses)}
		</MenuItem>
	);

	const courseTypeCreationMenuItem = (
		<MenuItem
			key="2"
			onClick={handleCloseCourses}
			component={Link}
			to="/courseTypes/create"
		>
			{formatMessage(messages.createCourseType)}
		</MenuItem>
	);

	const courseCreationMenuItem = (
		<MenuItem
			key="courseCreationMenuItem"
			onClick={handleCloseCourses}
			component={Link}
			to="/courses/create"
		>
			{formatMessage(messages.createCourses)}
		</MenuItem>
	);

	const enrollments = (
		<MenuItem
			key="4"
			onClick={handleCloseCourses}
			component={Link}
			to="/enrollments/maintain"
		>
			{formatMessage(messages.enrollments)}
		</MenuItem>
	);

	const courseMenu = (
		CourseView: ReactNode,
		CreateCourses: ReactNode,
		CourseTypeCreation: ReactNode,
		Enrollments: ReactNode
	) => (
		<Menu
			key="courseMenu"
			anchorEl={openCourses}
			keepMounted
			open={Boolean(openCourses)}
			onClose={handleCloseCourses}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			{CourseView}
			{currentUser?.role === Role.ADMIN
				? [CreateCourses, CourseTypeCreation, Enrollments]
				: null}
		</Menu>
	);

	const personsButton = (
		<Button
			key="personsButton"
			color="inherit"
			onClick={handleClickPersons}
			className={classes.buttons}
		>
			<FormattedMessage {...frequentMessages.persons} />
		</Button>
	);

	const addPersonMenuItem = (
		<MenuItem
			key="addPersonMenuItem"
			onClick={handleClosePersons}
			component={Link}
			to="/persons/add"
		>
			{formatMessage(messages.addPerson)}
		</MenuItem>
	);

	const viewPersonMenuItem = (
		<MenuItem
			key="viewPersonMenuItem"
			onClick={handleClosePersons}
			component={Link}
			to="/persons"
		>
			{formatMessage(messages.viewPerson)}
		</MenuItem>
	);

	const personMenu = (PersonAddition: ReactNode, PersonView: ReactNode) => (
		<Menu
			key="personMenu"
			anchorEl={openPersons}
			keepMounted
			open={Boolean(openPersons)}
			onClose={handleClosePersons}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			{currentUser?.role === Role.NORMAL ? PersonAddition : null}
			{PersonView}
		</Menu>
	);

	const selectLanguageButton = (
		<Button
			key="selectLanguageButton"
			onClick={handleClickLanguages}
			className={classes.buttons}
		>
			<Flag className={classes.icon} />
			<FormattedMessage {...messages.language} />
		</Button>
	);

	const languageMenu = (
		<Menu
			key="languageMenu"
			anchorEl={openLanguages}
			keepMounted
			open={Boolean(openLanguages)}
			onClose={handleCloseLanguages}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<MenuItem value="0" onClick={handleSelectLang}>
				{formatMessage(messages.english)}
			</MenuItem>
			<MenuItem value="1" onClick={handleSelectLang}>
				{formatMessage(messages.hungarian)}
			</MenuItem>
		</Menu>
	);

	const accountButton = (
		<Button
			key="accountButton"
			onClick={handleClickAccount}
			className={classes.buttons}
		>
			<AccountCircle className={classes.icon} />
			{isLoggedIn ? (
				currentUser?.userName || ""
			) : (
				<FormattedMessage {...messages.account} />
			)}
		</Button>
	);

	const accountMenu = (
		<AccountMenu
			key="accountMenu"
			handleCloseAccount={handleCloseAccount}
			anchorElement={openAccount}
			isUserLoggedIn={isLoggedIn}
		/>
	);

	//-----------------DISPLAY---------------------
	const displayDesktop = () => {
		return (
			<Toolbar className={classes.toolbar}>
				<Typography
					variant="h6"
					component="h1"
					className={classes.logo}
				>
					Ebihalak
				</Typography>
				<div>{getMenuButtons("row")}</div>
			</Toolbar>
		);
	};

	const displayMobile = () => {
		const handleDrawerOpen = () => setDrawerOpen(true);
		const handleDrawerClose = () => setDrawerOpen(false);

		return (
			<Toolbar className={classes.toolbar2}>
				<IconButton
					edge={"start"}
					color={"inherit"}
					onClick={handleDrawerOpen}
				>
					<MenuIcon />
				</IconButton>

				<Drawer
					anchor={"left"}
					open={drawerOpen}
					onClose={handleDrawerClose}
				>
					<div className={classes.drawerContainer}>
						{getMenuButtons("column")}
					</div>
				</Drawer>

				<Typography
					variant="h6"
					component="h1"
					className={classes.logo}
				>
					{formatMessage(messages.menu)}
				</Typography>
			</Toolbar>
		);
	};

	const getMenuButtons = (order: string) => {
		return (
			<div className={order}>
				{isLoggedIn
					? [
							homeButton,
							coursesButton,
							courseMenu(
								courseViewMenuItem,
								courseCreationMenuItem,
								courseTypeCreationMenuItem,
								enrollments
							),
							personsButton,
							personMenu(addPersonMenuItem, viewPersonMenuItem),
					  ]
					: null}
				{currentUser?.role === Role.ADMIN ? settingsButton : null}
				{selectLanguageButton}
				{languageMenu}
				{accountButton}
				{accountMenu}
			</div>
		);
	};

	return (
		<header>
			<AppBar className={classes.header}>
				{mobileView ? displayMobile() : displayDesktop()}
			</AppBar>
		</header>
	);
};
