import React, { FC, useEffect, useState } from "react";
import {
	TextField,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Paper,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonIcon from "@material-ui/icons/Person";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { LoginDto } from "../../types";
import { RequestResult, State } from "../../stores";
import { shouldShowErrorMessage } from "../utils";
import { useStyles } from "../../assets";

const messages = defineMessages({
	login: {
		id: "loginForm.title",
		defaultMessage: "Login",
	},

	emailForm: {
		id: "loginForm.email",
		defaultMessage: "Email",
	},

	passwordForm: {
		id: "loginForm.password",
		defaultMessage: "Password",
	},

	submitLogin: {
		id: "loginForm.submit",
		defaultMessage: "Login",
	},

	emailFormat: {
		id: "loginForm.emailFormat",
		defaultMessage: "The email has an invalid format.",
	},

	emailRequired: {
		id: "loginForm.emailRequired",
		defaultMessage: "The Email is required.",
	},

	passwordRequired: {
		id: "loginForm.passwordRequired",
		defaultMessage: "The Password is required.",
	},

	passwordContent: {
		id: "loginForm.passwordContent",
		defaultMessage:
			"The password must contain at least an uppercase, a lowercase letter, a number and a special character.",
	},

	passwordMinLength: {
		id: "loginForm.passwordMinLength",
		defaultMessage: "Password must be at least 8 characters long.",
	},

	passwordMaxLength: {
		id: "loginForm.passwordMaxLength",
		defaultMessage: "Password can be maximum 32 characters long",
	},

	passwordOrUsername: {
		id: "loginForm.passwordOrUsername",
		defaultMessage: "Invalid username or password.",
	},

	loginError: {
		id: "loginForm.loginError",
		defaultMessage: "An error occured at signing in.",
	},
});

let initialUser: LoginDto = {
	email: "",
	password: "",
};

type Props = {
	onSubmit: (user: LoginDto) => void;
	requestResult: RequestResult;
};

export const LoginForm: FC<Props> = ({ onSubmit, requestResult }) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);

	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (requestResult.state === State.DONE) {
			history.push("/");
		} else if (requestResult.state === State.ERROR) {
			if (requestResult.error == 400 || requestResult.error == 401) {
				enqueueSnackbar(formatMessage(messages.passwordOrUsername), {
					variant: "error",
				});
			} else {
				enqueueSnackbar(formatMessage(messages.loginError), {
					variant: "error",
				});
			}
			requestResult.reset();
		}
	}, [enqueueSnackbar, formatMessage, history, requestResult]);

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.email(formatMessage(messages.emailFormat))
			.required(formatMessage(messages.emailRequired)),
		password: yup
			.string()
			.matches(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,}$/,
				formatMessage(messages.passwordContent)
			)
			.min(8, formatMessage(messages.passwordMinLength))
			.max(32, formatMessage(messages.passwordMaxLength))
			.required(formatMessage(messages.passwordRequired)),
	});

	return (
		<Formik
			initialValues={initialUser}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				onSubmit(values);
			}}
		>
			{(formik) => (
				<div className="formDiv">
					<Paper variant="elevation" className="paper">
						<Form className="inputContainer">
							<PersonIcon className={classes.mainLogo} />
							<Typography variant="h4">
								<FormattedMessage {...messages.login} />
							</Typography>

							<div className="inputElement">
								<TextField
									name="email"
									label={formatMessage(messages.emailForm)}
									type="email"
									className="wide"
									variant="outlined"
									onChange={formik.handleChange}
									error={shouldShowErrorMessage(
										formik,
										"email"
									)}
								/>
								<ErrorMessage name="email">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									name="password"
									label={formatMessage(messages.passwordForm)}
									className="wide"
									variant="outlined"
									onChange={formik.handleChange}
									error={shouldShowErrorMessage(
										formik,
										"password"
									)}
									type={showPassword ? "text" : "password"}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={
														handleClickShowPassword
													}
												>
													{showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<ErrorMessage name="password">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<Button
								type="submit"
								variant="contained"
								color="primary"
							>
								<FormattedMessage {...messages.submitLogin} />
							</Button>
						</Form>
					</Paper>
				</div>
			)}
		</Formik>
	);
};
