import React, { FC } from "react";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import PoolIcon from "@material-ui/icons/Pool";

import { CourseType } from "../../types";
import { RequestResult } from "../../stores";
import { shouldShowErrorMessage } from "../utils";
import { SubmitProgress } from ".";
import "./formStyle.css";
import { useStyles } from "../../assets";

const initialValues: CourseType = {
	name: "",
};

const messages = defineMessages({
	title: {
		id: "courseTypeCreation.title",
		defaultMessage: "Create new course type",
	},

	typeName: {
		id: "courseTypeCreation.typeName",
		defaultMessage: "Name of type",
	},

	typeRequired: {
		id: "courseTypeCreation.typeRequired",
		defaultMessage: "Name of type required!",
	},

	submit: {
		id: "courseTypeCreation.submit",
		defaultMessage: "Add type",
	},
});

type Props = {
	onSubmit: (courseType: CourseType) => void;
	requestResult: RequestResult;
};

export const CourseTypeCreationForm: FC<Props> = ({
	onSubmit,
	requestResult,
}) => {
	const { formatMessage } = useIntl();
	const classes = useStyles();

	const validationSchema = yup.object().shape({
		name: yup.string().required(formatMessage(messages.typeRequired)),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				onSubmit(values);
			}}
		>
			{(formikProps) => (
				<div className="formDiv">
					<Paper variant="elevation" className="paper">
						<Form className="inputContainer">
							<PoolIcon className={classes.mainLogo} />
							<Typography variant="h4">
								<FormattedMessage {...messages.title} />
							</Typography>

							<div className="inputElement">
								<Field
									as={TextField}
									label={formatMessage(messages.typeName)}
									id="name"
									type="text"
									name="name"
									color="primary"
									variant="outlined"
									onChange={formikProps.handleChange}
									error={shouldShowErrorMessage(
										formikProps,
										"name"
									)}
									className="wide"
								/>
								<ErrorMessage name="name">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<Button
								type="submit"
								variant="contained"
								value="Submit"
								color="primary"
								disabled={!formikProps.isValid}
							>
								<FormattedMessage {...messages.submit} />
							</Button>

							<SubmitProgress requestResult={requestResult} />
						</Form>
					</Paper>
				</div>
			)}
		</Formik>
	);
};
