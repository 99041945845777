import React, { FC } from "react";
import { List, ListItem, ListItemText, Toolbar } from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";

import { frequentMessages } from "../../locales";
import { EnrollmentDto, PersonDto } from "../../types";
import { listStyles } from "./listStyle";

const messages = defineMessages({
	approved: {
		id: "approvedEnrollments.approved",
		defaultMessage: "Approved subscriptions",
	},
});

type Props = {
	enrollments: EnrollmentDto[];
};

export const ApprovedEnrollments: FC<Props> = ({ enrollments }) => {
	const { formatMessage } = useIntl();
	const classes = listStyles();

	const personInfo = (person: PersonDto) =>
		`${formatMessage(frequentMessages.addedBy)}: ${person.creatorName}`;

	return (
		<div className={classes.light}>
			<Toolbar variant="dense">
				{formatMessage(messages.approved)}
			</Toolbar>
			<List className={classes.smallList}>
				{enrollments.map((enrollment) => (
					<ListItem dense key={enrollment.id}>
						<ListItemText
							primary={enrollment.person.name}
							secondary={personInfo(enrollment.person)}
						/>
					</ListItem>
				))}
			</List>
		</div>
	);
};
