export enum State {
	INITIAL,
	PENDING,
	DONE,
	ERROR,
}

export type RequestResult = {
	state: State;
	error: number;
	reset(): void;
};
