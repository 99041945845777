import axios from "axios";

import { Training } from "../types";
import {
	AbsenceCreationDto,
	AbsenceRecuperationCreationDto,
} from "../types/absence";
import { API_URL, postRequestConfig } from "./config";

interface AbsenceApi {
	postNewAbsence(absence: AbsenceCreationDto): Promise<void>;
	getRecuperationTrainingsForAbsence(absenceId: number): Promise<Training[]>;
	setAbsenceRecuperation(
		id: number,
		recuperationDto: AbsenceRecuperationCreationDto
	): Promise<void>;
}

export const absenceApi: AbsenceApi = {
	async postNewAbsence(absence: AbsenceCreationDto) {
		await axios.post(`${API_URL}/absences`, absence, postRequestConfig);
	},
	async getRecuperationTrainingsForAbsence(absenceId: number) {
		const response = await axios.get(
			`${API_URL}/absences/${absenceId}/recuperations`
		);
		return response.data;
	},
	async setAbsenceRecuperation(
		id: number,
		recuperationDto: AbsenceRecuperationCreationDto
	) {
		await axios.put(`${API_URL}/absences/${id}`, recuperationDto);
	},
};
