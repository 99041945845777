import React, { FC, useEffect } from "react";

import { SettingsFrame } from "../components/dataDisplay/SettingsFrame";
import { LoadingSnackBar } from "../components";
import { SettingStore, State } from "../stores";
import { SettingsKey } from "../types";

export const SettingsPage: FC = () => {
	const { setSettings, getSetting, getSettingState, settings } =
		SettingStore.useContainer();

	useEffect(() => {
		getSetting();
	}, [getSetting]);

	const saveSettings = (id: string, value: string) => {
		if (settings && id in settings) {
			settings[id] = value;
			setSettings(settings);
		}
	};

	return getSettingState.state === State.DONE &&
		settings &&
		SettingsKey.Enrollment in settings ? (
		<SettingsFrame settings={settings} saveSettings={saveSettings} />
	) : (
		<LoadingSnackBar />
	);
};
