import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { courseTypeApi } from "../api";
import { CourseType } from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface CourseTypeStoreResult {
	courseTypeCreationState: RequestResult;
	getCourseTypesState: RequestResult;

	courseTypes: CourseType[];

	createCourseType(courseType: CourseType): Promise<void>;
	getCourseTypes(): Promise<void>;
}

const useCourseTypeStore = (): CourseTypeStoreResult => {
	const courseTypeCreationState = useRequestState();
	const getCourseTypesState = useRequestState();

	const [courseTypes, setCourseTypes] = useState<CourseType[]>([]);

	const createCourseType = useCallback(
		async (courseType: CourseType) => {
			try {
				courseTypeCreationState.pending();

				await courseTypeApi.postNewCourseType(courseType);

				courseTypeCreationState.done();
			} catch (error: any) {
				courseTypeCreationState.error(error?.response?.status ?? 0);
			}
		},
		[courseTypeCreationState]
	);

	const getCourseTypes = useCallback(async () => {
		try {
			getCourseTypesState.pending();

			const types: CourseType[] = await courseTypeApi.getCourseTypes();

			setCourseTypes(types);

			getCourseTypesState.done();
		} catch (error: any) {
			getCourseTypesState.error(error?.response?.status ?? 0);
		}
	}, [getCourseTypesState]);

	return {
		courseTypeCreationState: toRequestResult(courseTypeCreationState),
		getCourseTypesState: toRequestResult(getCourseTypesState),
		courseTypes,
		createCourseType,
		getCourseTypes,
	};
};

export const CourseTypeStore = createContainer(useCourseTypeStore);
