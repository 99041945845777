import React, { FC } from "react";
import { useIntl } from "react-intl";

import { SettingsKey } from "../../types";
import { OpenEnrollments } from "../settings";
import { listStyles } from "./listStyle";
import { frequentMessages } from "../../locales";
import { LightToolbar } from "..";

type Props = {
	settings: { [key: string]: string };
	saveSettings: (id: string, value: string) => void;
};

export const SettingsFrame: FC<Props> = ({ settings, saveSettings }) => {
	const { formatMessage } = useIntl();
	const classes = listStyles();

	return (
		<div className={classes.root}>
			<div className={classes.list}>
				<LightToolbar
					title={formatMessage(frequentMessages.settings)}
				/>
				<OpenEnrollments
					enrollmentState={settings[SettingsKey.Enrollment]}
					saveSettings={saveSettings}
				></OpenEnrollments>
			</div>
		</div>
	);
};
