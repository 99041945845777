import React, { FC, useState } from "react";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import PoolIcon from "@material-ui/icons/Pool";

import { frequentMessages } from "../../locales";
import { listStyles } from "./listStyle";
import { CourseReducedDto } from "../../types";
import { SearchField } from ".";

const messages = defineMessages({
	searchByType: {
		id: "subscriptionList.searchByType",
		defaultMessage: "Search by type...",
	},
});

type Props = {
	courseList: CourseReducedDto[];
	setSelected: (course: CourseReducedDto) => void;
};

export const CourseListView: FC<Props> = ({ courseList, setSelected }) => {
	const { formatMessage } = useIntl();
	const classes = listStyles();

	const [courses, setCourses] = useState(courseList);

	const updateCourses = (newList: CourseReducedDto[]) => setCourses(newList);

	const days = [
		formatMessage(frequentMessages.monday),
		formatMessage(frequentMessages.tuesday),
		formatMessage(frequentMessages.wednesday),
		formatMessage(frequentMessages.thursday),
		formatMessage(frequentMessages.friday),
		formatMessage(frequentMessages.saturday),
		formatMessage(frequentMessages.sunday),
	];

	const courseInfo = (course: CourseReducedDto) => [
		<Typography
			key="1"
			className="additionalInfo"
			variant="caption"
			component="div"
		>{`${formatMessage(frequentMessages.day)}: ${
			days[course.day - 1]
		}`}</Typography>,
		<Typography
			key="2"
			className="additionalInfo"
			variant="caption"
			component="div"
		>{`${formatMessage(frequentMessages.startTime)}: ${
			course.startHour
		}`}</Typography>,
	];

	return (
		<div className={classes.light}>
			<Toolbar variant="dense">
				{formatMessage(frequentMessages.courses)}
				<SearchField
					placeholder={formatMessage(messages.searchByType)}
					data={courseList}
					attribute={(course: CourseReducedDto) => course.type}
					setResult={updateCourses}
				/>
			</Toolbar>
			<List className={classes.smallList}>
				{courses.map((course: CourseReducedDto) => (
					<ListItem
						dense
						button
						key={courses.indexOf(course)}
						onClick={() => setSelected(course)}
					>
						<ListItemIcon>
							<PoolIcon />
						</ListItemIcon>
						<ListItemText
							disableTypography
							primary={course.type}
							secondary={courseInfo(course)}
						/>
					</ListItem>
				))}
			</List>
		</div>
	);
};
