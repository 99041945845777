import axios from "axios";

import { CourseCreationDto, CourseReducedDto } from "../types";
import { API_URL, postRequestConfig } from "./config";
import { Course } from "../types";

interface CourseApi {
	postNewCourse(course: CourseCreationDto): Promise<void>;
	listAllCourses(): Promise<CourseReducedDto[]>;
	listDetailedInformation(id: number): Promise<Course>;
}

export const courseApi: CourseApi = {
	async postNewCourse(course: CourseCreationDto) {
		await axios.post(`${API_URL}/courses`, course, postRequestConfig);
	},

	async listAllCourses() {
		const response = await axios(`${API_URL}/courses`);
		return response.data;
	},
	async listDetailedInformation(id: number) {
		const response = await axios(`${API_URL}/courses/${id}`);
		return response.data;
	},
};
