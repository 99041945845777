import { defineMessages } from "react-intl";

export const frequentMessages = defineMessages({
	add: {
		id: "frequent.add",
		defaultMessage: "Add",
	},
	addedBy: {
		id: "frequent.addedBy",
		defaultMessage: "Added by",
	},
	birthDate: {
		id: "frequent.birthDate",
		defaultMessage: "Birth Date",
	},
	calendar: {
		id: "frequent.calendar",
		defaultMessage: "Calendar",
	},
	cancelled: {
		id: "frequent.cancelled",
		defaultMessage: "Cancelled",
	},
	courses: {
		id: "frequent.courses",
		defaultMessage: "Courses",
	},
	day: {
		id: "frequent.day",
		defaultMessage: "Day",
	},
	edit: {
		id: "frequent.edit",
		defaultMessage: "Edit",
	},
	editPerson: {
		id: "frequent.editPerson",
		defaultMessage: "Edit person",
	},
	email: {
		id: "frequent.email",
		defaultMessage: "Email",
	},
	friday: {
		id: "frequent.friday",
		defaultMessage: "Friday",
	},
	list: {
		id: "frequent.list",
		defaultMessage: "List",
	},
	loading: {
		id: "frequent.loading",
		defaultMessage: "Loading...",
	},
	login: {
		id: "frequent.login",
		defaultMessage: "Login",
	},
	logout: {
		id: "frequent.logout",
		defaultMessage: "Logout",
	},
	monday: {
		id: "frequent.monday",
		defaultMessage: "Monday",
	},
	newAbsence: {
		id: "frequent.newAbsence",
		defaultMessage: "Add new absence",
	},
	newPerson: {
		id: "frequent.newPerson",
		defaultMessage: "Add new person",
	},
	notes: {
		id: "frequent.notes",
		defaultMessage: "Notes",
	},
	persons: {
		id: "frequent.persons",
		defaultMessage: "Persons",
	},
	registration: {
		id: "frequent.registration",
		defaultMessage: "Registration",
	},
	saturday: {
		id: "frequent.saturday",
		defaultMessage: "Saturday",
	},
	searchByName: {
		id: "frequent.searchByName",
		defaultMessage: "Search by name...",
	},
	settings: {
		id: "frequent.settings",
		defaultMessage: "Settings",
	},
	startTime: {
		id: "frequent.startTime",
		defaultMessage: "Start time",
	},
	sunday: {
		id: "frequent.sunday",
		defaultMessage: "Sunday",
	},
	tel: {
		id: "frequent.tel",
		defaultMessage: "Phone number",
	},
	thursday: {
		id: "frequent.thursday",
		defaultMessage: "Thursday",
	},
	tuesday: {
		id: "frequent.Tuesday",
		defaultMessage: "Tuesday",
	},
	type: {
		id: "frequent.type",
		defaultMessage: "Type",
	},
	wednesday: {
		id: "frequent.Wednesday",
		defaultMessage: "Wednesday",
	},
});
