import { Paper, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { frequentMessages } from "../../locales";
import { Course } from "../../types";
import "./dataDisplay.css";

type Props = {
	course: Course;
};

const messages = defineMessages({
	typeOfCourse: {
		id: "courseDetailsView.type",
		defaultMessage: "Type of course: ",
	},

	day: {
		id: "courseDetailsView.day",
		defaultMessage: "Day: ",
	},

	startHour: {
		id: "courseDetailsView.startHour",
		defaultMessage: "Start Hour: ",
	},

	endHour: {
		id: "courseDetailsView.endHour",
		defaultMessage: "End Hour: ",
	},

	nrOfEnrolledPersons: {
		id: "courseDetailsView.nrOfEnrolledPersons",
		defaultMessage: "Nr of enrolled persons: ",
	},

	participationLimit: {
		id: "courseDetailsView.participationLimit",
		defaultMessage: "Max participants: ",
	},

	extraPlaces: {
		id: "courseDetailsView.extraPlaces",
		defaultMessage: "Extra places: ",
	},

	title: {
		id: "courseDetailsView.title",
		defaultMessage: "Details",
	},

	startDate: {
		id: "courseDetailsView.startDate",
		defaultMessage: "Start date: ",
	},

	endDate: {
		id: "courseDetailsView.endDate",
		defaultMessage: "End date: ",
	},
});

export const CourseDetailsView: FC<Props> = ({ course }) => {
	const { formatMessage } = useIntl();
	const days = [
		formatMessage(frequentMessages.monday),
		formatMessage(frequentMessages.tuesday),
		formatMessage(frequentMessages.wednesday),
		formatMessage(frequentMessages.thursday),
		formatMessage(frequentMessages.friday),
		formatMessage(frequentMessages.saturday),
		formatMessage(frequentMessages.sunday),
	];
	const splitStartDate: string[] = course.startDate.split("T");
	const splitEndDate: string[] = course.endDate.split("T");

	return (
		<Paper variant="elevation" className="detailPaper">
			<Typography variant="h4" className="title">
				<FormattedMessage {...messages.title} />
			</Typography>
			<div className="flexDetailsLeft">
				<table>
					<tbody>
						<tr>
							<td>
								<h4>
									<FormattedMessage
										{...messages.typeOfCourse}
									/>
								</h4>
							</td>
							<td>{course.type}</td>
						</tr>
						<tr>
							<td>
								<h4>
									<FormattedMessage {...messages.day} />
								</h4>
							</td>
							<td>{days[Number(course.day) - 1]}</td>
						</tr>
						<tr>
							<td>
								<h4>
									<FormattedMessage {...messages.startHour} />
								</h4>
							</td>
							<td>{course.startHour}</td>
						</tr>
						<tr>
							<td>
								<h4>
									<FormattedMessage {...messages.endHour} />
								</h4>
							</td>
							<td>{course.endHour}</td>
						</tr>
						<tr>
							<td>
								<h4>
									<FormattedMessage {...messages.startDate} />
								</h4>
							</td>
							<td>{splitStartDate[0]}</td>
						</tr>
						<tr>
							<td>
								<h4>
									<FormattedMessage {...messages.endDate} />
								</h4>
							</td>
							<td>{splitEndDate[0]}</td>
						</tr>
						<tr>
							<td>
								<h4>
									<FormattedMessage
										{...messages.nrOfEnrolledPersons}
									/>
								</h4>
							</td>
							<td>{course.nrOfEnrolledPersons}</td>
						</tr>
						<tr>
							<td>
								<h4>
									<FormattedMessage
										{...messages.participationLimit}
									/>
								</h4>
							</td>
							<td>{course.participationLimit}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Paper>
	);
};
