import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

import { trainingApi } from "../api";
import { CancelTrainingDto, Training, TrainingDto } from "../types";
import { toRequestResult, useRequestState } from "./hooks";
import { RequestResult } from "./types";

export interface TrainingStoreResult {
	getTrainingState: RequestResult;
	oneTraining?: Training;
	getTraining(trainingId: number): Promise<void>;
	listTrainingsState: RequestResult;
	listTrainingsForPersonState: RequestResult;
	listTrainings(): Promise<void>;
	trainingList: TrainingDto[];
	cancelTrainingState: RequestResult;
	cancelTraining(training: CancelTrainingDto): Promise<void>;
	getCancellationReasonState: RequestResult;
	getCancellationReason(trainingId: number): Promise<string | undefined>;
}

const useTrainingStore = (): TrainingStoreResult => {
	const getTrainingState = useRequestState();
	const [oneTraining, setOneTraining] = useState<Training | undefined>(
		undefined
	);
	const listTrainingsState = useRequestState();
	const listTrainingsForPersonState = useRequestState();
	const [trainingList, setTrainingList] = useState<TrainingDto[]>([]);
	const cancelTrainingState = useRequestState();
	const getCancellationReasonState = useRequestState();

	const getTraining = useCallback(
		async (trainingId: number) => {
			try {
				getTrainingState.pending();

				const training = await trainingApi.getTraining(trainingId);
				setOneTraining(training);

				getTrainingState.done();
			} catch (error: any) {
				getTrainingState.error(error?.response?.status ?? 0);
			}
		},
		[getTrainingState]
	);

	const listTrainings = useCallback(async () => {
		try {
			listTrainingsState.pending();

			const result = await trainingApi.listAllTrainings();
			setTrainingList(result);

			listTrainingsState.done();
		} catch (error: any) {
			listTrainingsState.error(error?.response?.status ?? 0);
		}
	}, [listTrainingsState]);

	const cancelTraining = useCallback(
		async (training: CancelTrainingDto) => {
			try {
				cancelTrainingState.pending();
				await trainingApi.cancelTraining(training);
				cancelTrainingState.done();
			} catch (error: any) {
				cancelTrainingState.error(error?.response?.status ?? 0);
			}
		},
		[cancelTrainingState]
	);

	const getCancellationReason = useCallback(
		async (trainingId: number) => {
			try {
				getCancellationReasonState.pending();
				const reason = await trainingApi.getCancellationReason(
					trainingId
				);
				getCancellationReasonState.done();

				return reason;
			} catch (error: any) {
				getCancellationReasonState.error(error?.response?.status ?? 0);
			}
		},
		[getCancellationReasonState]
	);

	return {
		getTrainingState: toRequestResult(getTrainingState),
		oneTraining,
		getTraining,
		listTrainingsState: toRequestResult(listTrainingsState),
		listTrainingsForPersonState: toRequestResult(
			listTrainingsForPersonState
		),
		trainingList,
		listTrainings,
		cancelTrainingState: toRequestResult(cancelTrainingState),
		cancelTraining,
		getCancellationReasonState: toRequestResult(getCancellationReasonState),
		getCancellationReason,
	};
};

export const TrainingStore = createContainer(useTrainingStore);
