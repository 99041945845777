import axios from "axios";

import { WaitingListElementDto } from "../types";
import { API_URL, postRequestConfig } from "./config";

interface WaitingListApi {
	addPersonToTheWaitingList(
		waitingListElementDto: WaitingListElementDto
	): Promise<void>;

	getPersonsOnWaitingListByCourseId(
		courseId: number
	): Promise<WaitingListElementDto[]>;
}

export const waitingListApi: WaitingListApi = {
	async addPersonToTheWaitingList(
		waitingListElementDto: WaitingListElementDto
	) {
		await axios.post(
			`${API_URL}/waitingLists`,
			waitingListElementDto,
			postRequestConfig
		);
	},

	async getPersonsOnWaitingListByCourseId(courseId: number) {
		const response = await axios(
			`${API_URL}/courses/${courseId}/waitingList`
		);
		return response.data;
	},
};
