import React, { FC, useState } from "react";
import {
	TextField,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Paper,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as yup from "yup";
import PersonIcon from "@material-ui/icons/Person";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Redirect } from "react-router-dom";

import { RegistrationDto } from "../../types";
import { RequestResult, State } from "../../stores";
import { shouldShowErrorMessage } from "../utils";
import { frequentMessages } from "../../locales";
import { SubmitProgress } from ".";
import "./formStyle.css";
import { useStyles } from "../../assets";

let initialUser: RegistrationDto = {
	name: "",
	email: "",
	password: "",
	passwordConfirmation: "",
	addressTel: "",
};

const messages = defineMessages({
	linkToTheMinPage: {
		id: "registrationForm.BackToTheMP",
		defaultMessage: "Back To The Main Page",
	},

	formName: {
		id: "registrationForm.Name",
		defaultMessage: "Name",
	},

	formEmail: {
		id: "registrationForm.Email",
		defaultMessage: "Email",
	},

	formPassword: {
		id: "registrationForm.Password",
		defaultMessage: "Password",
	},

	formPasswordConf: {
		id: "registrationForm.PasswordConf",
		defaultMessage: "Password Confirmation",
	},

	formTelAdd: {
		id: "registrationForm.TelAdd",
		defaultMessage: "Telephone number or address",
	},

	resgiter: {
		id: "registrationForm.Register",
		defaultMessage: "Register",
	},

	requiredName: {
		id: "registrationForm.requiredName",
		defaultMessage: "The Name is required.",
	},

	requiredEmail: {
		id: "registrationForm.requiredEmail",
		defaultMessage: "The Email is required.",
	},

	requiredPassword: {
		id: "registrationForm.requiredPassword",
		defaultMessage: "The Password is required.",
	},

	requiredPasswConfig: {
		id: "registrationForm.requiredPasswConf",
		defaultMessage: "The Password Confirmation is required.",
	},

	requiredAddressTel: {
		id: "registrationForm.requiredAddressTel",
		defaultMessage: "The AddressTel field is required.",
	},

	emailFormat: {
		id: "registrationForm.emailFormat",
		defaultMessage: "The email has an invalid format.",
	},

	passwordContent: {
		id: "registrationForm.passwordContent",
		defaultMessage:
			"The password must contain at least an uppercase, a lowercase letter, a number and a special character.",
	},

	passwordMinLength: {
		id: "registrationForm.passwordMinLength",
		defaultMessage: "Password must be at least 8 characters long.",
	},

	passwordMaxLength: {
		id: "registrationForm.passwordMaxLength",
		defaultMessage: "Password can be maximum 32 characters long",
	},

	passwordConfirmation: {
		id: "registrationForm.passwordConfirmation",
		defaultMessage: "The Password Confirmation does not match.",
	},

	addressTelLength: {
		id: "registrationForm.addressTel",
		defaultMessage:
			"The field AddressTel must be a string with a minimum length of 10.",
	},
});

type Props = {
	onSubmit: (user: RegistrationDto) => void;
	requestResult: RequestResult;
};

export const RegistrationForm: FC<Props> = ({ onSubmit, requestResult }) => {
	const { formatMessage } = useIntl();
	const classes = useStyles();
	const validationSchema = yup.object().shape({
		name: yup.string().required(formatMessage(messages.requiredName)),
		email: yup
			.string()
			.email(formatMessage(messages.emailFormat))
			.required(formatMessage(messages.requiredEmail)),
		password: yup
			.string()
			.matches(
				/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,}$/,
				formatMessage(messages.passwordContent)
			)
			.min(8, formatMessage(messages.passwordMinLength))
			.max(32, formatMessage(messages.passwordMaxLength))
			.required(formatMessage(messages.requiredPassword)),
		passwordConfirmation: yup
			.string()
			.oneOf(
				[yup.ref("password"), null],
				formatMessage(messages.passwordConfirmation)
			)
			.required(formatMessage(messages.requiredPasswConfig)),
		addressTel: yup
			.string()
			.min(10, formatMessage(messages.addressTelLength))
			.required(formatMessage(messages.requiredAddressTel)),
	});

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword(!showPassword);

	const [showConfirmationPassword, setShowConfirmationPassword] =
		useState(false);
	const handleClickShowConfirmationPassword = () =>
		setShowConfirmationPassword(!showConfirmationPassword);

	return (
		<Formik
			initialValues={initialUser}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				onSubmit(values);
			}}
		>
			{(formik) => (
				<div className="formDiv">
					<Paper variant="elevation" className="paper">
						<Form className="inputContainer">
							<PersonIcon className={classes.mainLogo} />
							<Typography variant="h4">
								<FormattedMessage
									{...frequentMessages.registration}
								/>
							</Typography>

							<div className="inputElement">
								<Field
									as={TextField}
									name="name"
									label={formatMessage(messages.formName)}
									className="wide"
									variant="outlined"
									onChange={formik.handleChange}
									error={shouldShowErrorMessage(
										formik,
										"name"
									)}
								/>
								<ErrorMessage name="name">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									name="email"
									label={formatMessage(messages.formEmail)}
									type="email"
									className="wide"
									variant="outlined"
									onChange={formik.handleChange}
									error={shouldShowErrorMessage(
										formik,
										"email"
									)}
								/>
								<ErrorMessage name="email">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									name="password"
									label={formatMessage(messages.formPassword)}
									className="wide"
									variant="outlined"
									onChange={formik.handleChange}
									error={shouldShowErrorMessage(
										formik,
										"password"
									)}
									type={showPassword ? "text" : "password"}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={
														handleClickShowPassword
													}
												>
													{showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<ErrorMessage name="password">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									name="passwordConfirmation"
									label={formatMessage(
										messages.formPasswordConf
									)}
									className="wide"
									variant="outlined"
									onChange={formik.handleChange}
									error={shouldShowErrorMessage(
										formik,
										"passwordConfirmation"
									)}
									type={
										showConfirmationPassword
											? "text"
											: "password"
									}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={
														handleClickShowConfirmationPassword
													}
												>
													{showConfirmationPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<ErrorMessage name="passwordConfirmation">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<div className="inputElement">
								<Field
									as={TextField}
									name="addressTel"
									label={formatMessage(messages.formTelAdd)}
									className="wide"
									variant="outlined"
									onChange={formik.handleChange}
									error={shouldShowErrorMessage(
										formik,
										"addressTel"
									)}
								/>
								<ErrorMessage name="addressTel">
									{(msg) => (
										<div className="errorMessage">
											{msg}
										</div>
									)}
								</ErrorMessage>
							</div>

							<Button
								type="submit"
								variant="contained"
								color="primary"
							>
								<FormattedMessage {...messages.resgiter} />
							</Button>

							<SubmitProgress requestResult={requestResult} />

							{requestResult.state === State.DONE ? (
								<Redirect to="/" />
							) : null}
						</Form>
					</Paper>
				</div>
			)}
		</Formik>
	);
};
