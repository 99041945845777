import React, { FC } from "react";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	Chip,
} from "@material-ui/core";
import PoolIcon from "@material-ui/icons/Pool";
import "./dataDisplay.css";
import { useIntl } from "react-intl";

import { Absence } from "../../types";
import { withTime } from "..";
import { frequentMessages } from "../../locales";

type Props = {
	absences: Absence[];
	onSelected?: (absence: Absence) => void;
};

export const AbsenceList: FC<Props> = ({ absences, onSelected }) => {
	const { formatDate, formatTime, formatMessage } = useIntl();

	return (
		<List>
			{absences.map((absence) => (
				<ListItem
					dense
					button
					key={absence.id}
					onClick={() => {
						if (onSelected) onSelected(absence);
					}}
				>
					<ListItemIcon>
						<PoolIcon />
					</ListItemIcon>
					<ListItemText
						disableTypography
						primary={absence.absenceTraining.course.type}
						secondary={
							<div>
								<Typography
									className="additionalInfo"
									variant="caption"
									component="div"
								>
									{formatDate(absence.absenceTraining.date)}{" "}
									{formatTime(
										withTime(
											absence.absenceTraining.date,
											absence.absenceTraining.course
												.startHour
										)
									)}
								</Typography>
							</div>
						}
					/>
					{absence.absenceTraining.isCancelled ? (
						<ListItemSecondaryAction>
							<Chip
								size="medium"
								variant="outlined"
								label={formatMessage(
									frequentMessages.cancelled
								)}
							/>
						</ListItemSecondaryAction>
					) : null}
				</ListItem>
			))}
		</List>
	);
};
