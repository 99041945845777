import {
	List,
	ListItem,
	ListItemSecondaryAction,
	Paper,
	Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";

import { listStyles } from "./listStyle";
import { EnrollmentDto, PersonDto } from "../../types";

const messages = defineMessages({
	inProgress: {
		id: "enrolledPersonView.enrolled",
		defaultMessage: "Enrolled persons",
	},
	accepted: {
		id: "enrolledPersonView.accepted",
		defaultMessage: "Accepted",
	},
	pending: {
		id: "enrolledPersonView.pending",
		defaultMessage: "Pending",
	},
	waiting: {
		id: "enrolledPersonView.waiting",
		defaultMessage: "On waiting list",
	},
});

type Props = {
	approvedEnrollments: EnrollmentDto[];
	pendingEnrollments: EnrollmentDto[];
	waitingListElements: PersonDto[];
	className?: string;
};

export const EnrolledPersonView: FC<Props> = ({
	approvedEnrollments,
	pendingEnrollments,
	waitingListElements,
	className,
}) => {
	const classes = listStyles();
	const { formatMessage } = useIntl();

	const getListItem = (person: PersonDto, status: string) => (
		<div key={person.id} className="enrolledPersonList">
			<ListItem dense>
				<Typography noWrap style={{ width: "50%" }}>
					{person.name}
				</Typography>
				<ListItemSecondaryAction>
					{status === "accepted" ? (
						<Typography style={{ display: "flex", color: "green" }}>
							<CheckCircleRoundedIcon
								style={{ color: "green" }}
							/>
							{formatMessage(messages.accepted)}
						</Typography>
					) : status === "pending" ? (
						<Typography style={{ display: "flex", color: "gray" }}>
							<AutorenewOutlinedIcon style={{ color: "gray" }} />
							{formatMessage(messages.pending)}
						</Typography>
					) : (
						<Typography style={{ display: "flex", color: "gray" }}>
							<AutorenewOutlinedIcon style={{ color: "gray" }} />
							{formatMessage(messages.waiting)}
						</Typography>
					)}
				</ListItemSecondaryAction>
			</ListItem>
		</div>
	);

	return (
		<Paper variant="elevation" className="enrolledListPaper">
			<Typography variant="h6" className="title">
				<FormattedMessage {...messages.inProgress} />
			</Typography>
			<List
				className={
					className === undefined ? classes.fitList : className
				}
			>
				{approvedEnrollments.map((enrollment: EnrollmentDto) =>
					getListItem(enrollment.person, "accepted")
				)}
				{pendingEnrollments.map((enrollment: EnrollmentDto) =>
					getListItem(enrollment.person, "pending")
				)}
				{waitingListElements.map((waitingListElement: PersonDto) =>
					getListItem(waitingListElement, "waiting")
				)}
			</List>
		</Paper>
	);
};
