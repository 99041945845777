import React, { FC, useEffect } from "react";

import { LoginForm } from "../components";
import { State, UserStore } from "../stores";
import "./pages.css";

export const Login: FC = () => {
	const { loginUser, loginUserState, introspectUser } =
		UserStore.useContainer();

	useEffect(() => {
		if (loginUserState.state === State.DONE) {
			introspectUser();
			loginUserState.reset();
		}
	}, [introspectUser, loginUserState, loginUserState.state]);

	return (
		<div>
			<LoginForm onSubmit={loginUser} requestResult={loginUserState} />
		</div>
	);
};
